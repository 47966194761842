import React, {
  Suspense,
  useContext,
  useRef,
  useState,
  useEffect,
} from "react";
import { Switch, Route, withRouter, Redirect } from "react-router-dom";
import { SD_Context } from "./context/sd-context";
import New_Nav from "./Components/sd-navbar/sd-new-nav";
import Footer from "./Components/sd-footer/sd-footer";
import Sd_Routes from "./sd-routes";
import UserService from "./services/sd-user.service";
import Sd_error_page from "./pages/sd-error-pages/sd-error-response";
import ErrorBoundary from "./context/sd-error-boundary";
import Sd_loading from "./Components/sd-common-components/sd-loading";
import TmsScrollToTop from "./Utils/sd-common/tms-scroll-to-top";
import { ads, popupbanner } from "./Assets/imageList";
// import Maintenance from "./pages/maintenance/maintenance";

import Maintenance from "./pages/maintenance/maintenance";
// import useUniqueId from "./Utils/sd-common/tms-uniqueId";
const App = ({ history }) => {
  const {
    setUserData,
    refContainer,
    getTempleData,
    getFavorities,
    setlanguage,
    getVisitors,
    display,
    setDisplay,
    language,
  } = useContext(SD_Context);
  const refe = useRef(null);
  const [trig, setTrig] = useState(false);
  const [maintenance, setMaintenance] = useState(false);
  // const [tabRestriction, setTabRestriction] = useState(false);
  // const uniqueId = useUniqueId(setTabRestriction);
  const [loading, setLoading] = React.useState(true);

  const supportedLanguages = [
    { desc: "ENGLISH", code: "en-in" },
    { desc: "JAPANESE", code: "jp-in" },
    { desc: "GERMAN", code: "de-in" },
    { desc: "SPANISH", code: "es-in" },
    { desc: "తెలుగు", code: "te-in" },
  ];
  const onKeyEvent = (event) => {
    const eventCode = event.which || event.keyCode;
    if (eventCode === 27) {
      if (trig) {
        setDisplay(false);
        // setDisplay2(true);
        setTrig(false);
      }
    }
  };

  const handleKey = (e) => {
    if (!e.target.className) {
      return;
    }
    if (e.target.className === "sd-pop-up") {
      if (trig) {
        setDisplay(false);
        // setDisplay2(true);
        setTrig(false);
      }
    }
  };

  const closeFunction = () => {
    setTimeout(() => {
      setTrig(true);
      try {
        refe.current.focus();
      } catch (e) {
        // console.log("");
      }
    }, 3000);
    setTimeout(() => {
      if (display) {
        setDisplay(false);
      }
    }, 10000);
  };

  useEffect(async () => {
    const lang = supportedLanguages.find(
      (item) => item?.code === history?.location?.pathname?.split?.("/")?.[1]
    );
    if (lang) {
      setlanguage(lang);
    } else {
      setlanguage({ desc: "ENGLISH", code: "en-in" });
    }

    getVisitors();
    AuthFunction();
  }, []);

  useEffect(() => {
    getFavorities();
  }, [localStorage.getItem("accessToken")]);

  // useEffect(() => {
  //   // Listen for changes in localStorage to detect if the user opens the app in a new tab
  //   const storageEventListener = (e) => {
  //     console.log(e);
  //     if (e.key === "uniqueId" && e.newValue !== uniqueId) {
  //       // You may want to redirect or handle the situation in a way that makes sense for your application
  //       setTabRestriction(true);
  //     }
  //   };

  //   window.addEventListener("storage", storageEventListener);

  //   // Add a listener for the beforeunload event to clear the localStorage when the tab is closed
  //   const beforeUnloadListener = () => {
  //     localStorage.removeItem("uniqueId");
  //   };

  //   window.addEventListener("beforeunload", beforeUnloadListener);

  //   return () => {
  //     window.removeEventListener("storage", storageEventListener);
  //     window.removeEventListener("beforeunload", beforeUnloadListener);
  //   };
  // }, [uniqueId]);

  const AuthFunction = async () => {
    const res = await UserService.getTempleConfig();
    if (!res.onlineBookingEnabled) {
      setMaintenance(true);
      setLoading(false);
      return;
    }
    await getTempleData();
    if (localStorage.getItem("accessToken")) {
      try {
        setLoading(true);
        let data = await UserService.checkToken("APEDP");
        setUserData(data);
        setLoading(false);
      } catch (error) {
        localStorage.clear();
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  };

  return (
    <Suspense fallback={<Sd_loading />}>
      {!loading && !maintenance ? (
        <div className="sd-main" id="myDIV" name="myDIV">
          <TmsScrollToTop />
          <New_Nav />
          <div ref={refContainer}></div>
          <ErrorBoundary>
            <Switch>
              <Route
                path="/:lang"
                render={(props) => {
                  const lang = supportedLanguages.find(
                    (item) => item?.code === props?.match?.params?.lang
                  );

                  if (
                    props?.history?.location?.pathname?.replaceAll?.(
                      "/",
                      ""
                    ) === props?.match?.params?.lang &&
                    lang
                  ) {
                    return (
                      <Redirect to={`/${props?.match?.params?.lang}/home`} />
                    );
                  }
                  return lang ? (
                    <Sd_Routes {...props} />
                  ) : (
                    <Redirect
                      to={`/en-in${props?.history?.location?.pathname}${props?.history?.location?.search}`}
                    />
                  );
                }}
              />
              <Route
                path="*"
                render={(props) => {
                  return (
                    <Redirect
                      to={`/en-in${props?.history?.location?.pathname}${props?.history?.location?.search}`}
                    />
                  );
                }}
              />
            </Switch>
            <Footer />
          </ErrorBoundary>
          {/* {display &&
          history?.location.pathname === `/${language?.code}/home` ? (
            <div
              tabIndex="-1"
              ref={refe}
              autoFocus
              className={"sd-pop-up"}
              onKeyDown={(e) => onKeyEvent(e)}
              onMouseDown={(e) => {
                handleKey(e);
              }}
            >
              <div className="inside-pop">
                <img src={popupbanner} alt="" className="s-image" />

                {closeFunction()}
                <button
                  className={"display"}
                  onClick={() => {
                    setDisplay(false);
                    setTrig(false);
                  }}
                >
                  X
                </button>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "10px",
                  }}
                >
                  <a
                    onClick={() => {
                      localStorage.setItem("selected-temple", "SLNSD");
                      if (localStorage.getItem("accessToken")) {
                        history.push(
                          `/${language?.code}/devotee-app/online-booking/paroksha-seva`
                        );
                      } else {
                        history.push(
                          `/${language?.code}/devotee/signin?to=devotee-app/online-booking/paroksha-seva`
                        );
                      }
                    }}
                    className="sd-btn-orange"
                  >
                    Book Now
                  </a>
                </div>
              </div>
            </div>
          ) : (
            ""
          )} */}
        </div>
      ) : maintenance ? (
        <>
          <Maintenance />
        </>
      ) : (
        <Sd_loading />
      )}
      <div className="sd-error">
        <Sd_error_page type="resolution" />
      </div>
    </Suspense>
    // <Maintenance />
  );
};
App.displayName = "Apps";

export default withRouter(App);
