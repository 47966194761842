import React from "react";
import "../../Utils/sd-styles/sd-misc.scss";
// import Sd_breadcrumb from "../../Components/sd-common-components/sd-breadcrumb";
import ParokshaSevaOne from "../../Components/body/paroksha-seva/sd-paroksha-seva-component-one";
import { SD_divider } from "../../Assets/imageList";
const Sd_terms_and_conditions = () => {
  return (
    <div>
      {/* <Sd_breadcrumb from="Terms and Conditions"></Sd_breadcrumb> */}
      <ParokshaSevaOne from="Terms and Conditions" />
      <div className="sd-t-and-c">
        <div className="sd-t-and-c-inner">
          {/* <h2></h2> */}
          <div className="top white-bc">
            <h1>Terms and Conditions</h1>
            <img
              src={SD_divider}
              alt={SD_divider}
              style={{ height: "20px" }}
            ></img>
          </div>
          <b>
            <h4>Copyright:</h4>
          </b>
          <br />
          <p>
            This website is the copyright of AP Temples. All rights are
            reserved. Please carefully read the following copyright policy
            before using our website or any information/materials provided by AP
            Temples. By using our website, you agree to this copyright policy.
          </p>
          <p>
            <ol>
              <li>
                Ownership: All information provided by AP Temples, including but
                not limited to text, images, audio, video, and other content, is
                the property of AP Temples unless otherwise indicated. All
                rights are reserved.
              </li>
              <li>
                Limited Use: Our materials/information may be used for personal,
                non-commercial purposes only. You may not use the contents of
                our website for any commercial purposes or to promote any
                products or services without our prior written consent.
              </li>
              <li>
                Attribution: If you use our website, you must provide
                attribution to AP Temples. This includes identifying AP Temples
                as the source of the information/materials and providing a link
                to our website, if applicable.
              </li>
              <li>
                Modifications: You may not modify our website content in any way
                without our prior written consent.
              </li>
              <li>
                Prohibited Uses: You may not use our website or its contents for
                any illegal or harmful purposes, or in any way that could damage
                the reputation or interests of AP Temples.
              </li>
              <li>
                No Transfer of Ownership: Your use of our website or its
                contents does not transfer ownership or any other rights to you.
                Our materials always remain the property of AP Temples.
              </li>
              <li>
                User Content: If you submit any content to our website,
                including comments, reviews, or other materials, you grant us a
                non-exclusive, royalty-free, perpetual, irrevocable, and fully
                sublicensable right to use, reproduce, modify, adapt, publish,
                translate, create derivative works from, distribute, and display
                such content throughout the world in any media. You also grant
                us the right to use the name that you submit in connection with
                such content.
              </li>
              <li>
                Enforcement: We reserve the right to enforce our copyright and
                take legal action against any unauthorized use of our materials.
              </li>
              <li>
                Third-Party Materials: AP Temples may use materials that are
                owned by third parties, such as images or music. Our use of
                these materials is subject to the terms and conditions of the
                third-party owners, and we may not be able to grant permission
                for you to use these materials.
              </li>
              <li>
                Changes to Copyright Policy: We may update or modify this
                copyright policy from time to time without prior notice. Your
                continued use of our website/materials after any such changes
                constitutes your acceptance of the new copyright policy.
              </li>
              <li>
                Governing Law: This copyright policy shall be governed by and
                construed following the laws of the jurisdiction where AP
                Temples is located.
              </li>
            </ol>
          </p>

          <br />
          <b>
            <h4>Disclaimer:</h4>
          </b>
          <br />
          <p>
            Thank you for visiting https://www.aptemples.ap.gov.in, a portal of
            TMS by the AP Endowments Department, Government of Andhra Pradesh.
            Please carefully read the following disclaimer before using our
            website. By using our website, you agree to this disclaimer.
          </p>
          <p>
            <ol>
              <li>
                No Professional Advice: Our website provides information and
                resources for general informational purposes only. The
                information provided on our website is not intended to be a
                substitute for professional advice or services.
              </li>
              <li>
                Accuracy of Information: We strive to provide accurate and
                up-to-date information on our website. However, we make no
                representations or warranties of any kind, express or implied,
                about the completeness, accuracy, reliability, suitability, or
                availability concerning the website or the information,
                products, services, or related graphics contained on the website
                for any purpose.
              </li>
              <li>
                No Endorsement: Our website may provide links to external
                websites and resources that are not under our control. We do not
                endorse, recommend, or guarantee the accuracy, completeness, or
                usefulness of any external websites or resources.
              </li>
              <li>
                No Liability: We are not liable for any loss or damage,
                including without limitation, indirect or consequential loss or
                damage, or any loss or damage whatsoever arising from loss of
                data or profits arising out of or in connection with the use of
                our website.
              </li>
              <li>
                Changes to Disclaimer: We may update or modify this disclaimer
                from time to time without prior notice. Your continued use of
                our website after any such changes constitutes your acceptance
                of the new disclaimer.
              </li>
            </ol>
          </p>

          <br />
          <b>
            <h4>General Terms and Conditions :</h4>
          </b>
          <br />
          <p>
            Please carefully read the following general terms and conditions
            before using our website or its services. By using our website or
            its services, you agree to these terms and conditions.
          </p>
          <br />
          <p>
            <ol>
              <li>
                Hours of Operation: Our temple services operate during specific
                hours, which may vary depending on the day of the week,
                holidays, and special events. Please check our website or
                contact us for our current operating hours.
              </li>
              <li>
                Code of Conduct: A temple is a sacred space, and we expect all
                visitors to behave respectfully and appropriately. We do not
                tolerate any form of harassment, discrimination, or disruptive
                behaviour. Visitors who fail to comply with our code of conduct
                may be asked to leave the premises.
              </li>
              <li>
                Liability: AP temples is not responsible for any harm, injury,
                or damage that may result from your use of our services at the
                temple premises.{" "}
              </li>
              <li>
                No Commercial Activity: AP temples do not permit any commercial
                activity, such as sales, solicitation, or advertising, without
                our prior written consent.
              </li>
              <li>
                Photography and Video: Photography and video recording may be
                permitted inside the temple, but we ask that visitors respect
                the sacred nature of the space and the privacy of other
                visitors. Please ask for permission before taking any
                photographs or video recordings.
              </li>
              <li>
                Donation Policy: AP temples appreciate any contributions that
                visitors can make. Donations are voluntary and non-refundable.
              </li>
              <li>
                Confidentiality: We respect your privacy and confidentiality.
                Any personal information you provide to us will be kept
                confidential and will only be used to provide our services to
                you.
              </li>
              <li>
                Changes to Terms and Conditions: We may update or modify these
                terms and conditions from time to time without prior notice.
                Your continued use of our website after any such changes
                constitutes your acceptance of the new terms and conditions.
              </li>
              <li>
                Governing Law: These terms and conditions shall be governed by
                and construed following the laws of the jurisdiction where AP
                temples are located.
              </li>
            </ol>
          </p>
          <br />
          <p>By using our website, you agree to these terms and conditions.</p>
          <br />
          <b>
            <h4>Refund / Cancellation Policy:</h4>
          </b>
          <br />
          <p>
            Transactions which are successfully debited by the payment gateway
            but not confirmed by respective temples are deemed to be failed
            transactions. All such transactions are eligible for refund post
            reconciliation. In such transactions, money is refunded in 12-15
            working days.
          </p>
          <p>
            All bookings are FINAL.
            <span style={{ color: "#ff0000" }}>
              <b>
                Cancellation is strictly not allowed for successful
                transactions.
              </b>
            </span>
          </p>
          <p>
            For enquiries on refunds, write to
            <a href="mailto: endow-edpsec@gov.in"> endow-edpsec@gov.in</a>
          </p>
        </div>
      </div>
    </div>
  );
};
export default Sd_terms_and_conditions;
