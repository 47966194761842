import React, { useContext } from "react";
import {
  kanipakam,
  Srikalahasthi,
  Srisailam,
  Mahanadi,
  Kasapuram,
  Vijajayawada,
  DwaralkaTirumala,
  Anavaram,
  Simhachalam,
  SriKanakaMahalakshmi,
  Penugachiprolu,
} from "../../../Assets/imageList";
import { withRouter } from "react-router";
import "../../../Utils/sd-styles/sd-main.scss";
import "../../../Utils/sd-styles/sd-css/animate.min.css";
import { SD_Context } from "../../../context/sd-context";
const Sd_Home_component_Slider = ({ history, grayscale, click }) => {
  const { language } = useContext(SD_Context);

  const templeData = [
    {
      name: { "en-in": "Kanipakam", "te-in": "కాణిపాకం" },
      icon: kanipakam,
      path: "SSVVSD",
      link: "",
    },
    {
      name: { "en-in": "Srikalahasthi", "te-in": "శ్రీకాళహస్తి" },
      icon: Srikalahasthi,
      path: "SKHTD",
      link: "",
    },
    {
      name: { "en-in": "Srisailam", "te-in": "శ్రీశైలం" },
      icon: Srisailam,
      path: "SBMSD",
      link: "https://www.srisailadevasthanam.org/en-in/home",
    },
    {
      name: { "en-in": "Mahanandi", "te-in": "మహానంది" },
      icon: Mahanadi,
      path: "SMESD",
      link: "",
    },
    {
      name: { "en-in": "Kasapuram", "te-in": "కసాపురం" },
      icon: Kasapuram,
      path: "SNASVD",
      link: "",
    },
    {
      name: { "en-in": "Vijayawada", "te-in": "విజయవాడ" },
      icon: Vijajayawada,
      path: "SDMSD",
      link: "https://kanakadurgamma.org/en-in/home",
    },
    {
      name: { "en-in": "Dwaraka Tirumala", "te-in": "ద్వారకా తిరుమల" },
      icon: DwaralkaTirumala,
      path: "SVSVD",
      link: "",
    },
    {
      name: { "en-in": "Annavaram", "te-in": "అన్నవరం" },
      icon: Anavaram,
      path: "SVVSSD",
      link: "",
    },
    {
      name: { "en-in": "Simhachalam", "te-in": "సింహాచలం" },
      icon: Simhachalam,
      path: "SVLNSD",
      link: "",
    },
    {
      name: {
        "en-in": "Sri Kanaka Mahalakshmi",
        "te-in": "శ్రీ కనక మహాలక్ష్మి",
      },
      icon: SriKanakaMahalakshmi,
      path: "SKML",
      link: "",
    },
    {
      name: { "en-in": "Penuganchiprolu", "te-in": "పెనుగంచిప్రోలు" },
      icon: Penugachiprolu,
      path: "STADPPL",
      link: "",
    },
  ];
  return (
    <div className="sd-t1-slider">
      {templeData.map((item, i) => {
        return (
          <div
            className="temples-div"
            key={"temple" + i}
            onClick={() => {
              if (item?.link?.length > 0) {
                window.open(`${item?.link}`, "_blank");
              } else {
                history?.push(
                  `/${language?.code}/temples/${item.path}/aboutTemple`
                );
                click &&
                  (document.getElementById("tms-overlay").style.display =
                    "none");
              }
            }}
          >
            <img
              src={item.icon}
              alt="img"
              style={grayscale ? { filter: "grayscale(1)" } : {}}
            />
            <br />
            <p>{item.name[language?.code]}</p>
          </div>
        );
      })}
    </div>
  );
};
export default withRouter(Sd_Home_component_Slider);
