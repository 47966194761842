import {
  SD_gallery_01,
  SD_gallery_03,
  SD_gallery_07,
  accommodationb,
  parokshaseva,
} from "../../Assets/imageList";

const serviceData = {
  title: { "en-in": "Sevas and Darshanam", "te-in": "సేవ మరియు దర్శనం" },
  Darshanam: {
    title: { "en-in": "Darshanam", "te-in": "దర్శనం" },
    description: {
      "en-in":
        "An auspicious first glance at the divinity in the temple is called Darshanam. A darshanam allows devotees to transcend into a peaceful moment.",
      "te-in":
        "దేవాలయంలో భక్తి భావనతో దైవాన్ని దర్శించడం వలన మనోవికాసం మరియు ప్రశాంతత పొందుతారు. భక్తుల సౌకర్యార్థం దర్శనం టికెట్లు ఆన్లైన్ ద్వారా బుక్ చేసుకునే అవకాశం కల్పించడం జరిగింది. భక్తులు లాగిన్ చేసుకొని ఈ అవకాశాన్ని వినుయోగించుకోగలరు.",
    },

    image_url: SD_gallery_01,
    url: "sevas-and-darshanam/darshanam",
  },
  "Pratyaksha Seva": {
    title: { "en-in": "Pratyaksha Seva", "te-in": "ప్రత్యక్ష సేవ" },
    description: {
      "en-in":
        "Pratyaksha Seva is performing/attending Temple Sevas in Person. One may book a Pratyaksha Seva online and offline.",
      "te-in":
        "భక్తులు దేవాలయంలో జరిగే హోమం, అభిషేకం మరియు ఇతర కైంకర్యాలలో పాల్గొనేందుకు ప్రత్యక్ష సేవ బుకింగ్ అందుబాటులో ఉంది. ప్రత్యక్ష సేవ టికెట్లు ఆన్లైన్లో మరియు దేవస్థానం టికెట్ కౌంటర్లో కూడా అందుబాటులో ఉంటాయి.",
    },

    image_url: parokshaseva,
    url: "sevas-and-darshanam/paroksha-seva",
  },
  "Paroksha Seva": {
    title: { "en-in": "Paroksha Seva", "te-in": "పరోక్ష సేవ" },
    description: {
      "en-in":
        "Paroksha Seva is for those who can not visit the temple in person and wish to perform/attend Temple Sevas digitally. One may book a Paroksha Seva online.",
      "te-in":
        "భక్తులు ప్రపంచంలో ఎక్కడనుండైనా దేవస్థానంలో జరిగే పూజా కార్యక్రమాలలో పాల్గొనేందుకు ఈ పరోక్ష సేవను పరిచయం చేయడం జరిగింది. ఆన్లైన్ ద్వారా ఈ సేవా టికెట్లు బుక్ చేసుకొని, భక్తులు వారు ఉన్న ప్రదేశం నుండే దేవస్థానంలో జరిగే పూజలు లైవ్ ఛానెల్స్ ద్వారా వీక్షించి అశీస్సులు పొందవచ్చు. ",
    },

    image_url: SD_gallery_03,
    url: "sevas-and-darshanam/pratyaksha-seva",
  },
  Accommodation: {
    title: { "en-in": "Accommodation", "te-in": "వసతి సౌకర్యం" },
    description: {
      "en-in": `The trust will provide Accommodation and community halls to poor people who visit Kanipakam from all over India without any distinction as to Caste, Creed, Religion...`,
      "te-in":
        "దేవస్థానం నిర్వహణలో ఉన్నటువంటి వసతి గృహాలు మరియు సత్రాల ద్వారా దేశ విదేశాలనుండి విచ్చేసే భక్తులకు కుల మత భేదాలు లేకుండా వసతి సౌకర్యం అందించడం జరుగుతుంది.",
    },
    image_url: accommodationb,
  },
  Donations: {
    title: { "en-in": "Donations", "te-in": "విరాళం" },
    description: {
      "en-in": `Devotees can donate to AP TEMPLES  Temple via online and offline payments. Srikanakadurgamma
    Devasthanam website accepts easy payment methods like credit card, debit card and UPI
    transactions for donations.`,
      "te-in":
        "భక్తి భావనతో చేసే ఒక్కొక్క విరాళం, మన హైందవ దేవాలయాలను అభివృద్ధి చేయడానికై ఉపయోగించబడతాయి. భక్తులు ఆన్లైన్ మరియు దేవస్థానం విరాళాల కౌంటర్ నందు విరాళం ఇవ్వవచ్చు.",
    },
    image_url: SD_gallery_07,
  },
};

export default serviceData;
