import React, { useContext, useRef } from "react";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { withRouter } from "react-router";
import { SD_divider, intellectual } from "../../../Assets/imageList";
import "../../../Utils/sd-styles/sd-about.scss";
import Sd_loading from "../../sd-common-components/sd-loading";
import Sd_comming_soon from "../../../Components/sd-common-components/comming-soon";
import FooterData from "../../../Utils/data/footer.data";
import { SD_Context } from "../../../context/sd-context";

const Values_Component = ({ match, visible, history }) => {
  //-------------------------------------------------------------states--------------------------------------------------------//

  const [type, setType] = useState(match.params.type);
  const { language } = useContext(SD_Context);
  const [imageLoading, setImageLoading] = useState(false);
  const titleRef = useRef();

  //-------------------------------------------------------------states--------------------------------------------------------//

  //-------------------------------------------------------------useEffects--------------------------------------------------------//

  useEffect(() => {
    if (match?.params?.type) {
      setType(match?.params?.type);
    } else {
      setType("sri-anjaneya-swamy-temple-manikanta-eswara-swamy-temple");
    }
    titleRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }, [match?.params?.type]);

  useEffect(() => {
    setImageLoading(true);
    setImageLoading(false);

    // if (data[type]?.image === "") {
    //   setImageLoading(false);
    // }
    titleRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }, [type]);

  useEffect(() => {
    history?.push(`/${language?.code}/values/${type}`);
  }, [type]);

  //-------------------------------------------------------------useEffects--------------------------------------------------------//

  const data = {
    "common-good-fund": {
      heading: `Common Good Fund`,
      description1: (
        <>
          The Common Good Fund is created under Section 70(1)(a) of Endt Act
          from the contributions made by the Charitable and Hindu Religious
          Institutions whose annual income exceeds Rs. 50,000. They contribute
          at the rate of 5% of the assessable income of the institutions. The
          funds are utilized for (1) Renovation, Preservation, Maintenance of
          Hindu temples in needy circumstances, (2) Dhoopa Deepa Naivedyam to
          needy temples, (3) Establishment and maintenance of Veda Pathasalas,
          Agama Pathasalas and Schools for training, in Archakatwam,
          Adhyapapaktwam etc.,
          <br />
          <br />
          The Common Good Fund Committee has evolved certain guidelines for
          granting funds to the needy temples as under:
          <br />
          <br />
          <ol style={{ listStyle: "disc", marginLeft: "15px" }}>
            <li>
              Preference will be given to renovation, preservation and
              maintenance of old temples in rural areas with little resources.
              Temples with heritage / historic value to get top priority.
            </li>
            <li>
              Temples whose gross annual income exceeds Rs.5.00 lakhs are not
              eligible for CGF grant.
            </li>
            <li>
              Temples within Municipal Limits are not entitled for C.G.F. grant.
            </li>
            <li>
              Renovation to be carried out by a valid Trust Board or Executive
              Officer or a Renovation Committee constituted for the purpose.
            </li>
            <li>
              No temple is entitled for C.G.F. grant for a second time within a
              period of 5 years.
            </li>
            <li>
              The scheme for construction of Hindu temples in Weaker Section
              Housing Colonies was introduced in 1980. The local public in the
              weaker section locality have to make a token contribution of 5% of
              the estimated cost. The Endowments Department provides the balance
              amount and Tirumala Tirupathi Devasthanam provides necessary idols
              free of cost (Lord Rama, Krishna, Siva, Venkateswara etc.,) as
              required by the local public for their worship. This unit cost of
              such small temples has been enhanced from time to time and now
              stands at Rs.1.00 Lakh.
            </li>
            <li>
              Grant in Aid from Common Good Fund is provided only when the
              Temple / Local Public make a matching Contribution of 20% of
              estimated amount in backward areas of Rayalaseema and north
              coastal Andhra and 30% of the estimated amount in respect of other
              regions.
            </li>
            <li>
              Recently the Department has started making releases under CGF for
              various works by using the online banking system to bring greater
              transparency in the payment system.
            </li>
          </ol>
        </>
      ),
      image: "",
    },
    "veda-and-agama-patashala": {
      heading: `Veda and Agama Patashala`,
      description1: (
        <>
          The Hindu religion which is also known traditionally as Sanatana
          Dharma is essentially based on Veda and Agama sastra. There are 8
          different Agamas to meet the requirements of different streams of
          Hindu religion. The Endowments Department has a sacred responsibility
          of protecting, preserving and nuturing Vedas and Agamas and for this
          purpose the department is following two broad strategies:
          <br />
          <br />
          <b>1) Spread of Veda and Agama Patasalas</b>
          <br />
          The objective is to create trained manpower to meet the requirements
          of various temples and other religious Institutions. The Department is
          presently running Agama/Veda patasalas at the following places.
          <br />
          <b>
            2) Utilisation of the services of Veda and Agama pandits in temples
          </b>
          <br />
          The Department is also utilizing the services of Veda Pandits in all
          major temples of the state. These Veda pandits undertake Vedic
          chanting in the temples daily besides participating in Yagnas, Yagams
          etc.,
          <br />
          <b>3) Sanskrit Education</b>
          <br />
          Encouraging Sanskrit language and education is one of the objectives
          of Department. The following Devasthanams are running full fledged
          oriental colleges and high schools. (1) Simhachalam (2) Annavaram (3)
          Dwaraka Tirumala (4) Ponnuru. These Institutions are furthering
          education in Sanskrit in accordance with the traditional curriculum.
        </>
      ),
      image: "",
    },
    "hindu-dharmika-parishad": {
      heading: `Hindu Dharmika Parishad`,
      description1: (
        <>
          {" "}
          The A.P. Hindu Dharmika Parishad is the highest governing body for all
          matters pertaining to the administration of Hindu religious
          Institutions like temples, mutts and peethams in the state of Andhra
          Pradesh. The A.P.Hindu Dharmika Parishad will supervise, guide and
          regulate the functioning of the Endowments Department in Andhra
          Pradesh. It will also advise the Government on all matters pertaining
          to the administration of Hindu temples and charitable trusts.
        </>
      ),
      image: "",
    },
    "dhoopa-deepa-naivedyam": {
      heading: `Dhoopa Deepa Naivedyam`,
      description1: (
        <>
          There are several small temples in remote villages which are in a poor
          condition. There were several representation to the department from
          the public to provide assistance to such temples. Hence a scheme was
          evolved to provide an amount of Rs. 2,500/- per month (Rs. 1,000/- per
          month for Dhoopa Deepa Naivedyam and Rs. 1,500/- per month towards
          honorarium to Archaka) to each temple located in rural areas which
          have no source of income. The department has selected more than 3000
          temples under this scheme. Under this scheme, an amount of Nearly
          Rs.80.00 lakhs is being spent.
          <br />
          <br />
          Recently the department has started sending these amounts to Archakas
          using the online banking system to bring greater transparency in
          payments.{" "}
        </>
      ),
      image: intellectual,
    },
    "archaka-welfare-fund": {
      heading: `Archaka Welfare Fund`,
      description1: (
        <>
          In pursuance of the directions of Supreme Court of India, a Trust
          named A.P. Endowments Archakas and other Employees Welfare Fund Trust
          has been created to take up welfare measures to Archakas and other
          employees working in the temples of the State. For this purpose a
          corpus fund was in the state, created by collecting funds from every
          Religious and Charitable Institution in the State. As per Section 65
          of the Endowments Act every temple is liable to contribute annually 3%
          of its income to the Archaka and other employees Welfare Fund.
          <br />
          <br />
          Financial assistance is being given to the Archakas and other
          employees of temples out of the interest derived from the corpus fund.
          These schemes include
          <br />
          <ul style={{ listStyle: "disc", marginLeft: "15px" }}>
            <li>Death Exgratia to bereaved Archaka families.</li>
            <li>Medical Aid.</li>
            <li>Loan for performance of marriage.</li>
            <li>Conduct of Refresher courses to Archakas.</li>
            <li>Housing Loans.</li>
            <li>Education Loans.</li>
            <li>Scholarships for Veda and Agama students.</li>
          </ul>
        </>
      ),
      image: "",
    },
    sita: {
      heading: `State Institute of Temple Administration (SITA)`,
      description1: (
        <>
          <div>
            <p>
              While the demand for large number of employees is steadily
              increasing in the Department to cater to the Administrative and
              Religious needs of the Temples across the State, the supply is
              very paltry. As there has been dearth of the qualified employees,
              the Department had to engage the employees on Contractual /
              Outsourcing basis, who do not have exposure to the functional
              dynamics of the Endowments Department. Further the employees taken
              into the regular service on compassionate grounds are also to be
              properly oriented on the functioning of the Department. Such
              employees are novices who have to be trained for their efficient
              discharge of their duties. Similarly, the new recruits of APPSC in
              respect of Clerical and Official cadres are also to be trained in
              the Administrative areas of the Department. Thus, there is a
              strong need to establish a State level Training Institute for
              imparting training to the employees and officers at all levels in
              all the wings. It has been the long longing of the Endowments
              Department to have a separate training institution for itself.
              Earlier, Dr. MCR Human Resource Development Institute used to
              conduct training programmes on Temple Culture and Administration
              for the employees of the Endowments Department. Already we have
              Sri Venkateswara Employees Training Academy (SVETA) of T.T.D. to
              organize training programmes for the employees of T.T.D. only.
              With the emergence of SITA the training needs of employees of the
              Endowments Department are fulfilled in respect of the Endowments
              Department of Andhra Pradesh.
            </p>
            <p>
              State Institute of Temple Administration (SITA) was originally
              established during November, 2010 by the Andhra Pradesh Dharmik
              Parishad in the undivided state of A.P under the administrative
              control of Commissioner of Endowments with a mandate to organize
              training programmes for all the employees of the Endowments
              Department. Accordingly SITA successfully ogrganized about 200
              training programmes for the Religious, Administrative, Engineering
              &amp; Sculpture Wings, etc. in the Endowments Department for their
              capacity- building, skill development and professional updating in
              job-related functions and for improvement in the quality of the
              Temple related functions.
            </p>
            <p>
              <b>S.I.T.A.</b> is again revived as a separate entity under the
              Government of Andhra Pradesh during April 2015. SITA functions in
              coordination with Sri Venkateswara Vedic University (SVVU), SVETA
              of TTD certain institutions of religious and cultural training
              established by certain Peethams and Mutts.
            </p>

            <b>TRAINING MANDATE OF THE GOVERNMENT</b>
            <p>
              The guidelines stipulated by the Department of Personnel and
              Training (DOP&amp;T) Government of India are being followed
              wherever necessary. Keeping in pace with the Training Mandate of
              the Government of Andhra Pradesh in pursuance of the National
              Training Policy, 2010 (NTP) of the Government of Inida, SITA
              undertakes the capacity-building, skill development and
              professional excellence – oriented programmes on regular basis.
              S.I.T.A. caters to the needs of different employees working in
              different wings such as Religious, Administrative, Engineering and
              Sculpture (Stapathi) wings etc. in the Department under
              headquarters-based and de-centralized basis. The major temples
              under the administrative control of the Department provide
              facilities for conducting training programmes under de-centralized
              basis.
            </p>
            <b>S.I.T.A. AT WORK</b>
            <p>
              Apart from its mandate of Capacity-building and skill-development,
              SITA undertakes several responsibilities like recruitment of Veda
              Parayanadars, organization/ coordination of important fairs and
              festivals at the major temples and actively associated with
              training of religious and administrative staff involved in Dharma
              Prachara programmes of the Department.
            </p>
          </div>
        </>
      ),
      image: "",
    },
    "founding-family-members": {
      heading: `Founder Family Members`,
      description1: <Sd_comming_soon />,
      image: "",
    },
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {" "}
          {data[type]?.seo_tag?.title ?? "AP TEMPLES Official Website"}
        </title>
        <meta
          name="description"
          content={data[type]?.seo_tag?.desc ?? "AP TEMPLES Official Website"}
        />
        <meta name="keywords" content={data[type]?.seo_tag?.keywords} />
      </Helmet>
      <div className="sd-about-temple-history">
        {visible && (
          <div className="sd-about-temple-history-left">
            <ol>
              {FooterData("values")?.data?.map((item, i) => {
                return (
                  <li
                    key={i + "dfsdf"}
                    className={type === item.path ? "sd-active" : ""}
                    onClick={() => {
                      setType(item.path);
                    }}
                  >
                    {item?.name[language?.code]}
                  </li>
                );
              })}
            </ol>
          </div>
        )}{" "}
        <div className="sd-about-temple-history-right">
          <div style={{ display: "block" }}>
            <div className="sd-about-temple-history-right-top" ref={titleRef}>
              <h1>
                <span> {data[type]?.heading}</span> {}
              </h1>
              <img
                src={SD_divider}
                style={{ height: "20px" }}
                alt={"divider"}
              ></img>
            </div>
            <div className="sd-about-temple-history-right-bottom">
              <div className="sd-athrb-top">
                {/* {data[type]?.image !== "" ? (
                  <img
                    src={data[type]?.image}
                    alt="alternative"
                    align="left"
                    className="sd-imgs"
                    onLoadStart={() => {
                      setImageLoading(true);
                    }}
                    onLoad={() => {
                      setImageLoading(false);
                    }}
                  />
                ) : (
                  ""
                )} */}
                <p>{data[type]?.description1}</p>
              </div>
              {/* <div className="sd-athrb-bottom">{data[type].description2}</div> */}
            </div>
          </div>
          <div
            style={{
              display: !imageLoading ? "none" : "block",
              height: "30%",
            }}
          >
            <Sd_loading />
          </div>
        </div>
      </div>
    </>
  );
};
export default withRouter(Values_Component);
