/* eslint-disable react/jsx-pascal-case */
import React, { useContext } from "react";
import {
  // banner2,
  bannerAlt,
  // sd_donation_banner,
  // SD_ganapathi_homam_banner_img,
  // SD_parokshaseva_banner_img,
} from "../../../Assets/imageList";
import "../../../Utils/sd-styles/sd-parokshaseva.scss";
import Sd_breadcrumb from "../../sd-common-components/sd-breadcrumb";
import { SD_Context } from "../../../context/sd-context";
import commonData from "../../../Utils/data/common.data";
import serviceData from "../../../Utils/data/serviceData.data";

const SD_Paroksha_Seva_One = ({ from, data }) => {
  //-------------------------------------------------------------consts-----------------------------------------------------------//
  const donationArray = [
    "Annaprasadam",
    "Aagama Patasala",
    "GoSamrakshana",
    "E-Hundi",
    "Pranadana Trust",
  ];
  const { language } = useContext(SD_Context);
  //-------------------------------------------------------------consts-----------------------------------------------------------//

  return (
    <div className="sd-Paroksha-one">
      <div className="banner" style={{ background: "#D01B22" }}>
        <div className="sd-banner-top">
          <h1>
            {from === "Paroksha-seva" ? (
              <span>
                {
                  serviceData["Paroksha Seva"].title[language?.code]?.split(
                    " "
                  )[0]
                }
                <span>
                  {
                    serviceData["Paroksha Seva"].title[language?.code]?.split(
                      " "
                    )[1]
                  }
                </span>
              </span>
            ) : from === "darshanam" ? (
              <span>{serviceData["Darshanam"].title[language?.code]}</span>
            ) : from === "donations" ? (
              <span>{serviceData["Donations"].title[language?.code]}</span>
            ) : from === "Saswata Seva" ? (
              <span>
                Saswatha<span>Seva</span>
              </span>
            ) : from === "Support-Contact Us" ? (
              <span>Contact Us</span>
            ) : from === "faqs" ? (
              "FAQs"
            ) : from === "Support-Facilities to Pilgrims" ? (
              "Facilities to Pilgrims"
            ) : (
              // : from === "Support-Connectivity" ? (
              //   "Connectivity"
              // )
              <span>{from} </span>
            )}

            {/* {pratyakshasevaArray.includes(from) ? (
              <span>{from.split("-")[1]}</span>
            ) : (
              "asd"
            )} */}
          </h1>
          <h4>
            {from === "Paroksha-seva"
              ? commonData?.slogan[language?.code]
              : from === "darshanam" ||
                from === "donations" ||
                donationArray.includes(from)
              ? commonData?.slogan[language?.code]
              : commonData?.slogan[language?.code]}
          </h4>
        </div>

        <div className="sd-banner-bottom">
          <img src={bannerAlt} alt="icon" />
        </div>
      </div>
      <Sd_breadcrumb
        data={data}
        from={from === "Paroksha-seva" ? "Paroksha-seva" : from}
      />
    </div>
  );
};
export default SD_Paroksha_Seva_One;
