// import {FALSE} from "node-sass";
import React from "react";
import { withRouter } from "react-router-dom";
import {
  facebook,
  insta,
  SD_cart_icon,
  SD_Dropdown_Arrow_icon,
  SD_Logo_circle,
  sd_minus_icon,
  sd_plus_icon,
  SD_Print_a_ticket_icon,
  twitter,
  youtube,
} from "../../Assets/imageList";
// import { toast } from "react-toastify";
import { SD_Context } from "../../context/sd-context";
import NavBarData from "../../Utils/data/navbar.data";
import HeaderData from "../../Utils/data/header.data";
const Sd_mobile_left_sider = ({
  setSider,
  setDropTwo,
  drop2,
  setDropOne,
  drop1,
  history,
  drop3,
  drop7,
  setDropSeven,
  drop5,
  setDropFive,
  setDropThree,
  setDropFour,
  drop6,
  setDropSix,
  drop8,
  drop9,
  drop10,
  setDropTen,
  setDropNine,
  setDropEight,
  drop11,
  drop12,
  setDrop11,
  setDrop12,
  drop13,
  setDrop13,
  drop14,
  setDrop14,
  drop15,
  setDrop15,
}) => {
  const { language, setlanguage, languageList } = React.useContext(SD_Context);
  //-------------------------------------------------------------funtion-----------------------------------------------------------//
  const Route_push = () => {
    setDropOne(false);
    setDropTwo(false);
    setDrop11(false);
    setDrop13(false);
    setDrop12(false);
    setDropFive(false);
    setDropThree(false);
    setDropSix(false);
    setDropFour(false);
    setDropTen(false);
    setDropNine(false);
    setDropEight(false);
    setDropSeven(false);
    setDrop14(false);
    setDrop15(false);
    setSider(false);
  };

  //-------------------------------------------------------------funtion-----------------------------------------------------------//
  return (
    <div className="inner">
      <div className="inner-nav-top">
        <div className="left">
          <img
            className="logo"
            src={SD_Logo_circle}
            alt="icon"
            onClick={() => {
              Route_push();
              history?.push(`/${language?.code}/home`);
            }}
          />
        </div>

        <div className="right">
          <div className="dropdown">
            <button
              className="btn"
              type="button"
              id="dropdownMenuButton1"
              data-toggle="dropdown"
            >
              {language.desc} <img src={SD_Dropdown_Arrow_icon} alt="icon" />
            </button>
            <div
              className="dropdown-menu"
              aria-labelledby="dropdownMenuButton1"
            >
              {languageList?.map((item, i) => {
                return (
                  <p
                    key={i}
                    className="dropdown-item"
                    onClick={() => {
                      setlanguage(item);
                    }}
                  >
                    {item?.desc}
                  </p>
                );
              })}
            </div>
          </div>

          <img src={SD_cart_icon} alt={SD_cart_icon} />
          <button
            type="button"
            onClick={() => {
              setSider(false);
            }}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>

      <div className="inner-bottom">
        <div className="inner-bottom-t">
          <div
            onClick={() => {
              Route_push();
              history?.push(`/${language?.code}/devotee-app/booking-history`);
            }}
          >
            <img src={SD_Print_a_ticket_icon} alt={SD_Print_a_ticket_icon} />
            {HeaderData["printATicket"][language?.code]}
          </div>
          <div
          // onClick={() => {
          //   if (localStorage.getItem("accessToken")) {
          //     history?.push(
          //       `/${language?.code}/devotee-app/archaka-application`
          //     );
          //   } else {
          //     history?.push(
          //       `/${language?.code}/devotee/signin?to=devotee-app/archaka-application`
          //     );
          //   }
          // }}
          >
            {HeaderData["volunteer"][language?.code]}
          </div>
          <div
            onClick={() => {
              Route_push();
              history?.push(`/${language?.code}/shop`);
            }}
          >
            SHOP
          </div>
        </div>
        <div className="inner-bottom-b">
          <div
            className="between"
            onClick={() => {
              Route_push();
              history?.push(`/${language?.code}/temples`);
              setDropThree(!drop3);
            }}
          >
            {HeaderData["temples"][language?.code]}
          </div>

          <div className={drop3 ? "drop-three block" : "drop-three none"}>
            <div
              onClick={() => {
                Route_push();
                history?.push(`/${language?.code}/about`);
              }}
            >
              {HeaderData["overview"][language?.code]}
            </div>
            <div
              onClick={() => {
                setDropEight(!drop8);
                // Route_push();
                // history.push(
                //   `/${language?.code}/about/the-temple/main-temple`
                // );
              }}
            >
              <span>The Temple</span>
              {drop8 ? (
                <img
                  src={sd_minus_icon}
                  alt="-"
                  onClick={() => {
                    setDropEight(false);
                  }}
                ></img>
              ) : (
                <img
                  src={sd_plus_icon}
                  onClick={() => {
                    setDropEight(true);
                  }}
                />
              )}
            </div>
            <div className={drop8 ? "drop-8 block" : "drop-8 none"}>
              {NavBarData("about")?.["the-temple"]?.map((data, i) => {
                return (
                  <div
                    key={i}
                    onClick={() => {
                      Route_push();
                      history?.push(
                        `/${language?.code}/about/the-temple/${data?.path}`
                      );
                    }}
                  >
                    {data?.name}
                  </div>
                );
              })}
            </div>

            <div
              onClick={() => {
                setDropNine(!drop9);
              }}
            >
              <span>The Temple History</span>
              {drop9 ? (
                <img
                  src={sd_minus_icon}
                  alt="-"
                  onClick={() => {
                    setDropNine(false);
                  }}
                ></img>
              ) : (
                <img
                  src={sd_plus_icon}
                  onClick={() => {
                    setDropNine(true);
                  }}
                />
              )}
            </div>
            <div className={drop9 ? "drop-9 block" : "drop-9 none"}>
              {NavBarData("about")?.["temple-story"]?.map((data, i) => {
                return (
                  <div
                    key={i}
                    onClick={() => {
                      Route_push();

                      history?.push(
                        `/${language?.code}/about/the-temple-history/${data?.path}`
                      );
                    }}
                  >
                    {data?.name}
                  </div>
                );
              })}
            </div>

            <div
              onClick={() => {
                setDropTen(!drop10);
              }}
            >
              <span>General Information</span>
              {drop10 ? (
                <img
                  src={sd_minus_icon}
                  alt="-"
                  onClick={() => {
                    setDropTen(false);
                  }}
                ></img>
              ) : (
                <img
                  src={sd_plus_icon}
                  onClick={() => {
                    setDropTen(true);
                  }}
                />
              )}
            </div>
            <div className={drop10 ? "drop-10 block" : "drop-10 none"}>
              {NavBarData("about")?.["general-information"]?.map((data, i) => {
                return (
                  <div
                    key={i}
                    onClick={() => {
                      Route_push();

                      history?.push(
                        `/${language?.code}/about/general-information/${data?.path}`
                      );
                    }}
                  >
                    {data?.name}
                  </div>
                );
              })}
            </div>
          </div>

          <div
            className="between"
            onClick={() => {
              if (drop2) {
                setDropTwo(false);
              }
              setDropOne(!drop1);
            }}
          >
            {HeaderData["sevas"][language?.code]}
            {drop1 ? (
              <img src={sd_minus_icon} alt="-"></img>
            ) : (
              <img src={sd_plus_icon} alt="+" />
            )}
          </div>

          <div className={drop1 ? "drop-one block" : "drop-one none"}>
            <div
              onClick={() => {
                Route_push();
                history?.push(`/${language?.code}/sevas-and-darshanam`);
              }}
            >
              {HeaderData["overview"][language?.code]}
            </div>

            <div
              onClick={() => {
                setDrop11(!drop11);
                history?.push(
                  `/${language?.code}/sevas-and-darshanam/darshanam`
                );
              }}
            >
              {HeaderData["darshanam"][language?.code]}
            </div>
            {/* <div className={drop11 ? "drop-11 block" : "drop-11 none"}>
              {NavBarData("seva-darshanam")?.["Darshanam"]?.map((data, i) => {
                return (
                  <div
                    key={i}
                    onClick={() => {
                      Route_push();

                      history?.push(
                        `/${language?.code}/sevas-and-darshanam/darshanam/${data?.path}`
                      );
                    }}
                  >
                    {data?.name}
                  </div>
                );
              })}
            </div> */}
            <div
              onClick={() => {
                setDrop12(!drop12);
                history?.push(
                  `/${language?.code}/sevas-and-darshanam/pratyaksha-seva`
                );
              }}
            >
              {HeaderData["pratyakshaSeva"][language?.code]}
            </div>

            <div
              onClick={() => {
                setDropTwo(!drop2);
                history?.push(
                  `/${language?.code}/sevas-and-darshanam/paroksha-seva`
                );
              }}
            >
              {HeaderData["parokshaSeva"][language?.code]}
            </div>
          </div>

          <div
            className="between"
            onClick={() => {
              Route_push();
              history?.push(`/${language?.code}/donations`);
            }}
          >
            {HeaderData["donation"][language?.code]}
          </div>

          <div
            className="between"
            onClick={() => {
              setDropFive(!drop5);
            }}
          >
            {HeaderData["onlineBooking"][language?.code]}

            {drop5 ? (
              <img src={sd_minus_icon} alt="-"></img>
            ) : (
              <img src={sd_plus_icon} alt="+" />
            )}
          </div>

          <div className={drop5 ? "drop-five block" : "drop-five none"}>
            <div
              onClick={() => {
                Route_push();
                history?.push(`/${language?.code}/online-booking`);
              }}
            >
              {HeaderData["overview"][language?.code]}
            </div>
            {NavBarData("online-data")?.map((data, i) => {
              return (
                <div
                  key={i}
                  onClick={() => {
                    Route_push();
                    if (localStorage.getItem("accessToken")) {
                      history?.push(
                        `/${language?.code}/devotee-app/online-booking/${data?.path}`
                      );
                    } else {
                      history?.push(
                        `/${language?.code}/devotee/signin?to=devotee-app/online-booking/${data?.path}`
                      );
                    }
                  }}
                >
                  {data?.name[language?.code]}
                </div>
              );
            })}
          </div>

          <div
            className="between"
            onClick={() => {
              setDropSix(!drop6);
            }}
          >
            {HeaderData["mediaRoom"][language?.code]}

            {drop6 ? (
              <img src={sd_minus_icon} alt="-"></img>
            ) : (
              <img src={sd_plus_icon} alt="+" />
            )}
          </div>

          <div className={drop6 ? "drop-six block" : "drop-six none"}>
            <div
              onClick={() => {
                Route_push();
                history?.push(`/${language?.code}/media-room`);
              }}
            >
              {HeaderData["overview"][language?.code]}
            </div>
            <div
              onClick={() => {
                Route_push();

                history?.push(`/${language?.code}/media-room/media-kit`);
              }}
            >
              {HeaderData["mediaKit"][language?.code]}
            </div>
            <div>
              <span>{HeaderData["gallery"][language?.code]} </span>
              {drop13 ? (
                <img
                  src={sd_minus_icon}
                  alt="-"
                  onClick={() => {
                    setDrop13(false);
                  }}
                ></img>
              ) : (
                <img
                  src={sd_plus_icon}
                  onClick={() => {
                    setDrop13(true);
                  }}
                />
              )}
            </div>
            <div className={drop13 ? "drop-13 block" : "drop-13 none"}>
              {NavBarData("media")?.["gallery"]?.map((data, i) => {
                return (
                  <div
                    key={i}
                    onClick={() => {
                      Route_push();
                      history?.push(
                        `/${language?.code}/media-room/gallery/${data?.path}`
                      );
                    }}
                  >
                    {data?.name[language?.code]}
                  </div>
                );
              })}
            </div>
            <div>
              <span>{HeaderData["whatsNew"][language?.code]} </span>
              {drop14 ? (
                <img
                  src={sd_minus_icon}
                  alt="-"
                  onClick={() => {
                    setDrop14(false);
                  }}
                ></img>
              ) : (
                <img
                  src={sd_plus_icon}
                  onClick={() => {
                    setDrop14(true);
                  }}
                />
              )}
            </div>
            <div className={drop14 ? "drop-14 block" : "drop-14 none"}>
              {NavBarData("media")?.["whats-new"]?.map((data, i) => {
                return (
                  <div
                    key={i}
                    onClick={() => {
                      Route_push();

                      history?.push(
                        `/${language?.code}/media-room/whats-new/${data?.path}`
                      );
                    }}
                  >
                    {data?.name[language?.code]}
                  </div>
                );
              })}
              <div
                onClick={() => {
                  Route_push();

                  history?.push(
                    `/${language?.code}/media-room/whats-new/temple-diary`
                  );
                }}
              >
                Temple Dairy{" "}
              </div>
            </div>
            <div>
              <span
                onClick={() => {
                  // Route_push();
                  // history.push(`/${language?.code}/media-room/press`);
                }}
              >
                {HeaderData["press"][language?.code]}{" "}
              </span>
              {drop15 ? (
                <img
                  src={sd_minus_icon}
                  alt="-"
                  onClick={() => {
                    setDrop15(false);
                  }}
                ></img>
              ) : (
                <img
                  src={sd_plus_icon}
                  onClick={() => {
                    setDrop15(true);
                  }}
                />
              )}
            </div>
            <div className={drop15 ? "drop-15 block" : "drop-15 none"}>
              {NavBarData("media")?.["press"]?.map((data, i) => {
                return (
                  <div
                    key={i}
                    onClick={() => {
                      Route_push();

                      history?.push(
                        `/${language?.code}/media-room/press/${data?.path}`
                      );
                    }}
                  >
                    {data?.name[language?.code]}
                  </div>
                );
              })}
            </div>
            <div>
              <span
                onClick={() => {
                  Route_push();
                  history.push(`/${language?.code}/media-room/rti-act`);
                }}
              >
                {HeaderData["RTI"][language?.code]}
              </span>
            </div>{" "}
            <div>
              <span
                onClick={() => {
                  Route_push();
                  history.push(`/${language?.code}/media-room/tenders`);
                }}
              >
                {HeaderData["tenders"][language?.code]}
              </span>
            </div>
          </div>

          <div
            className="between"
            onClick={() => {
              setDropSeven(!drop7);
            }}
          >
            {HeaderData["support"][language?.code]}
            {drop7 ? (
              <img src={sd_minus_icon} alt="-"></img>
            ) : (
              <img src={sd_plus_icon} alt="+" />
            )}
          </div>
          <div
            className={drop7 ? "drop-seven block" : "drop-seven none"}
            style={{ marginBottom: "50px" }}
          >
            <div
              onClick={() => {
                Route_push();

                history?.push(`/${language?.code}/support`);
              }}
            >
              {HeaderData["overview"][language?.code]}
            </div>
            {NavBarData("support")?.map((data, i) => {
              return (
                <div
                  key={i}
                  onClick={() => {
                    Route_push();
                    history?.push(`/${language?.code}/support/${data?.path}`);
                  }}
                >
                  {data?.name[language?.code]}
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="inner-footer">
        <img
          // onClick={() => {
          //   window.open(
          //     "https://www.facebook.com/SrisailaDevasthanamOfficial",
          //     "_blank"
          //   );
          // }}
          src={facebook}
          alt="icon"
        />
        <img
          // onClick={() => {
          //   window.open(
          //     "https://twitter.com/AnnavaramDevas1?t=CMCZDtumNhJOWCcXQL26JQ&s=09",
          //     "_blank"
          //   );
          // }}
          src={twitter}
          alt="icon"
        />

        <img
          // onClick={() => {
          //   window.open("https://www.instagram.com/sbmsdevasthanam/", "_blank");
          // }}
          src={insta}
          alt="icon"
        />
        <img
          onClick={() => {
            // window.open(
            //   "https://www.youtube.com/channel/UCNIzL2dT2gZxQae2LHXvl0A",
            //   "_blank"
            // );
          }}
          src={youtube}
          alt="icon"
        />
      </div>
    </div>
  );
};
export default withRouter(Sd_mobile_left_sider);
