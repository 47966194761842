import React, { lazy } from "react";
import { Route, Switch } from "react-router-dom";
const Sd_Home = lazy(() => import("./pages/sd-home/sd-home"));
const SD_Dashboard = lazy(() => import("./pages/sd-dashboard"));
import Sd_terms_and_conditions from "./pages/sd-misc/sd-terms-and-condotions";
import Sd_private_policy from "./pages/sd-misc/sd-private-policy";

//About---------------------
import Sd_faqs_all from "./Components/sd-common-components/sd-faqs-all";
import Sd_support from "./pages/sd-support/sd-support";
//Temples
const TmsTemples = lazy(() => import("./pages/temples/tms-temples"));
const TmsTemplePage = lazy(() => import("./pages/temples/tms-temple-page"));
const TmsTemplesLive = lazy(() => import("./pages/temples/tms-temples-live"));

//Sevas-and-darshanam
const Sd_sevas_and_darshanam_paroksha_seva = lazy(() =>
  import("./pages/sd-sevas-and-darshanam/sd-sevas-and-darshanam-paroksha-seva")
);
const Sd_sevas_and_darshanam_pratyakshaseva = lazy(() =>
  import("./pages/sd-sevas-and-darshanam/sd-sevas-and-darshanam-pratyashaseva")
);
const Sd_sevas_and_darshanam = lazy(() =>
  import("./pages/sd-sevas-and-darshanam/sd-sevas-and-darshasnam")
);

//darshanam
const Sd_sevas_and_darshanam_darshanam = lazy(() =>
  import("./pages/sd-sevas-and-darshanam/sd-sevas-and-darshanam-darshanam")
);

//pratyaksha seva
const Sd_gallery_full_view = lazy(() =>
  import("./Components/sd-common-components/sd-gallery-full-view")
);

// paroksha seva
const Sd_temple_dairy = lazy(() =>
  import("./pages/sd-media-room/sd-media-room-temple-dairy")
);

//donations
const Sd_donations = lazy(() => import("./pages/sd-donation/sd-donations"));

//Online Booking
const Sd_online_booking = lazy(() =>
  import("./pages/sd-online-booking/sd-online-booking")
);

//Media Room
const Sd_media_room_press_hoc = lazy(() =>
  import("./pages/sd-media-room/sd-media-room-press/sd-media-room-press-hoc")
);
const Sd_media_room_tenders = lazy(() =>
  import("./pages/sd-media-room/sd-media-room-tenders")
);
const Sd_media_room_Announcements = lazy(() =>
  import("./pages/sd-media-room/sd-media-room-announcements")
);
const Sd_media_room = lazy(() => import("./pages/sd-media-room/sd-media-room"));
const Sd_media_room_events = lazy(() =>
  import("./pages/sd-media-room/sd-media-room-events")
);
const Sd_media_room_media_kit = lazy(() =>
  import("./pages/sd-media-room/sd-media-room-media-kit")
);
const Sd_media_room_media_queries = lazy(() =>
  import("./pages/sd-media-room/sd-media-room-media-queries")
);
const Sd_media_room_news = lazy(() =>
  import("./pages/sd-media-room/sd-media-room-news")
);
const Sd_media_room_newsletter = lazy(() =>
  import("./pages/sd-media-room/sd-media-room-newsletter")
);
const Sd_media_room_photo_gallery = lazy(() =>
  import("./pages/sd-media-room/sd-media-room-photo-gallery")
);
const Sd_media_room_press_coverage = lazy(() =>
  import("./pages/sd-media-room/sd-media-room-press-coverage")
);
const Sd_media_room_press_releases = lazy(() =>
  import("./pages/sd-media-room/sd-media-room-press-releases")
);
const Sd_media_room_publications = lazy(() =>
  import("./pages/sd-media-room/sd-media-room-publications")
);
const Sd_media_room_rti_act = lazy(() =>
  import("./pages/sd-media-room/sd-media-room-rti-act")
);
const Sd_media_room_video_gallery = lazy(() =>
  import("./pages/sd-media-room/sd-media-room-video-gallery")
);
//volunteer-get involved
const Sd_get_involved = lazy(() =>
  import("./pages/sd-get-involved/sd-get-involved")
);
//shop
const Sd_shop = lazy(() => import("./pages/sd-shop/sd-shop"));
//cart
const Sd_cart = lazy(() => import("./pages/sd-cart/sd-cart"));
//support
const Sd_support_call_center = lazy(() =>
  import("./pages/sd-support/sd-support-call-center")
);
const Sd_support_connectivity = lazy(() =>
  import("./pages/sd-support/sd-support-conectivity")
);
const Sd_support_contactUs = lazy(() =>
  import("./pages/sd-support/sd-support-contacUs")
);
const Sd_deactivate_account = lazy(() =>
  import("./pages/sd-support/Sd-support-deactivate-account")
);
const Sd_support_facilities_to_pilgrims = lazy(() =>
  import("./pages/sd-support/sd-support-facilities-to-pilgrims")
);
const Sd_support_live_chat = lazy(() =>
  import("./pages/sd-support/sd-support-live-chat")
);
const Sd_support_faqs = lazy(() =>
  import("./pages/sd-support/sd-support-faqs")
);
const SD_Login = lazy(() => import("./pages/sd-login-register-pages/sd-login"));
const SD_Signup = lazy(() =>
  import("./pages/sd-login-register-pages/sd-singup")
);
const SD_Assistance = lazy(() => import("./pages/assistance/sd-assistance"));
const SD_Reset = lazy(() => import("./pages/sd-login-register-pages/sd-reset"));
const SD_Verify_OTP = lazy(() =>
  import("./pages/sd-login-register-pages/sd-verify-otp")
);
const SD_Signup_Step_Two = lazy(() =>
  import("./pages/sd-login-register-pages/sd-register-step-two")
);
const SD_Dont_Remember = lazy(() =>
  import("./pages/sd-login-register-pages/sd-dont-remember")
);
const SD_Guest_SignUp = lazy(() =>
  import("./pages/sd-login-register-pages/sd-guest-login")
);
const Sd_404 = lazy(() => import("./pages/sd-error-pages/sd-404"));
const Sd_trademark = lazy(() => import("./pages/sd-misc/sd-trademarks"));
const Sd_faqs_Main = lazy(() => import("./pages/sd-help/sd-faqs"));
const Sd_Print_a_ticket = lazy(() =>
  import("./pages/sd-misc/sd-print-a-ticket")
);
const TmsAboutDepartment = lazy(() =>
  import("./pages/temples/tms-about-department")
);
//public-dashboard
const SdPublicDasboard = lazy(() =>
  import("./pages/sd-public-dashboard/sd-public-dashboard")
);
import "./Utils/sd-styles/sd-app.scss";
import Sd_Values from "./pages/sd-values/sd-values";
import TmsMaaOoruMaaGudi from "./pages/temples/tms-maa-ooru-maa-gudi";
import tmsMaaOoruMaaGudi from "./pages/temples/tms-maa-ooru-maa-gudi";
const Sd_Routes = () => {
  return (
    <Switch>
      <Route exact path="/:lang/home" component={Sd_Home} />
      <Route exact path="/:lang/devotee-app/*" component={SD_Dashboard} />
      <Route exact path="/:lang/devotee/signin" component={SD_Login} />
      <Route exact path="/:lang/devotee/signup" component={SD_Signup} />

      <Route
        exact
        path="/:lang/endownment-department"
        component={TmsAboutDepartment}
      />
      <Route
        exact
        path="/:lang/media-room/press/:type"
        component={Sd_media_room_press_hoc}
      />
      <Route
        exact
        path="/:lang/devotee/print-a-ticket"
        component={Sd_Print_a_ticket}
      />
      <Route
        exact
        path="/:lang/devotee/public-dashboard"
        component={SdPublicDasboard}
      />

      <Route
        exact
        path="/:lang/terms-and-conditions"
        component={Sd_terms_and_conditions}
      />
      <Route exact path="/:lang/privacy-policy" component={Sd_private_policy} />
      <Route exact path="/:lang/faqs/:type" component={Sd_faqs_Main} />
      <Route exact path="/:lang/support/faqs-all" component={Sd_faqs_all} />
      <Route
        exact
        path="/:lang/devotee/signup/verify"
        component={SD_Signup_Step_Two}
      />
      <Route
        exact
        path="/:lang/media-room/photo-gallery"
        component={Sd_gallery_full_view}
      />
      <Route
        exact
        path="/:lang/maa-ooru-maa-gudi"
        component={tmsMaaOoruMaaGudi}
      />
      <Route exact path="/:lang/devotee/assistance" component={SD_Assistance} />
      <Route
        exact
        path="/:lang/devotee/reset/verify/:num"
        component={SD_Verify_OTP}
      />
      <Route exact path="/:lang/trademarks" component={Sd_trademark} />
      <Route exact path="/:lang/devotee/reset" component={SD_Reset} />
      <Route
        exact
        path="/:lang/devotee/reset/help"
        component={SD_Dont_Remember}
      />
      <Route exact path="/:lang/guestLogin" component={SD_Guest_SignUp} />

      <Route
        exact
        path="/:lang/sevas-and-darshanam"
        component={Sd_sevas_and_darshanam}
      />
      <Route
        exact
        path="/:lang/sevas-and-darshanam/paroksha-seva"
        component={Sd_sevas_and_darshanam_paroksha_seva}
      />
      <Route
        exact
        path="/:lang/sevas-and-darshanam/pratyaksha-seva"
        component={Sd_sevas_and_darshanam_pratyakshaseva}
      />

      <Route
        exact
        path="/:lang/sevas-and-darshanam/darshanam"
        component={Sd_sevas_and_darshanam_darshanam}
      />

      <Route exact path="/:lang/donations/" component={Sd_donations} />

      <Route exact path="/:lang/online-booking" component={Sd_online_booking} />

      <Route exact path="/:lang/media-room" component={Sd_media_room} />
      <Route
        exact
        path="/:lang/media-room/media-kit"
        component={Sd_media_room_media_kit}
      />
      <Route
        exact
        path="/:lang/media-room/gallery/photo-gallery"
        component={Sd_media_room_photo_gallery}
      />
      <Route
        exact
        path="/:lang/media-room/video-gallery"
        component={Sd_media_room_video_gallery}
      />
      <Route
        exact
        path="/:lang/media-room/whats-new/events"
        component={Sd_media_room_events}
      />
      <Route
        exact
        path="/:lang/media-room/whats-new/publications"
        component={Sd_media_room_publications}
      />
      <Route
        exact
        path="/:lang/media-room/whats-new/temple-diary"
        component={Sd_temple_dairy}
      />
      <Route
        exact
        path="/:lang/media-room/whats-new/newsletter"
        component={Sd_media_room_newsletter}
      />
      <Route
        exact
        path="/:lang/media-room/tenders"
        component={Sd_media_room_tenders}
      />
      <Route
        exact
        path="/:lang/media-room/announcements"
        component={Sd_media_room_Announcements}
      />
      <Route
        exact
        path="/:lang/media-room/rti-act"
        component={Sd_media_room_rti_act}
      />
      <Route
        exact
        path="/:lang/media-room/press/press-releases"
        component={Sd_media_room_press_releases}
      />
      <Route
        exact
        path="/:lang/media-room/press/temple-news"
        component={Sd_media_room_news}
      />
      <Route
        exact
        path="/:lang/media-room/press/press-coverage"
        component={Sd_media_room_press_coverage}
      />
      <Route
        exacts
        path="/:lang/media-room/press/media-queries"
        component={Sd_media_room_media_queries}
      />
      <Route exact path="/:lang/volunteer" component={Sd_get_involved} />
      <Route exact path="/:lang/shop" component={Sd_shop} />
      <Route exact path="/:lang/cart" component={Sd_cart} />
      <Route
        exact
        path="/:lang/support/call-center"
        component={Sd_support_call_center}
      />
      <Route
        exact
        path="/:lang/support/contact-us"
        component={Sd_support_contactUs}
      />
      {/* <Route
        exact
        path="/:lang/support/contact-us/deactivate-account"
        component={Sd_deactivate_account}
      /> */}
      <Route
        exact
        path="/:lang/support/support-faqs"
        component={Sd_support_faqs}
      />
      <Route
        exact
        path="/:lang/support/live-chat"
        component={Sd_support_live_chat}
      />
      <Route
        exact
        path="/:lang/support/facilities-to-pilgrims"
        component={Sd_support_facilities_to_pilgrims}
      />
      {/* <Route
        exact
        path="/:lang/support/connectivity"
        component={Sd_support_connectivity}
      /> */}
      <Route exact path="/:lang/values/:type" component={Sd_Values} />
      <Route exact path="/:lang/temples" component={TmsTemples} />
      <Route
        exact
        path="/:lang/temples/:type/:page"
        component={TmsTemplePage}
      />
      <Route exact path="/:lang/temples-live" component={TmsTemplesLive} />
      <Route exact path="/:lang/support" component={Sd_support} />
      <Route path="*" component={Sd_404} />
    </Switch>
  );
};
export default Sd_Routes;
