import React, { useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";
import Sd_faqs_semi_view from "../../Components/sd-common-components/sd-faqs-semi-view";
import Sd_breadcrumb from "../../Components/sd-common-components/sd-breadcrumb";
import { maaooru2, SD_divider } from "../../Assets/imageList";
const TmsMaaOoruMaaGudi = ({ match }) => {
  const titleRef = useRef();

  useEffect(() => {
    titleRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }, [match?.params?.type]);

  return (
    <div>
      <Sd_breadcrumb from="maa-ooru" />
      <div className="top" style={{ background: "white" }}>
        <h1>
          <span>Maa Ooru</span> Maa Gudi
        </h1>
        <img src={SD_divider} alt="-" style={{ height: "20px" }}></img>
      </div>
      <br />
      <br />
      <div className="container">
        <p style={{ fontSize: "15px", textAlign: "center" }}>
          Many people who left their villages / native places for jobs to other
          cities and foreign countries often remember the temple, they visited /
          played around/ prayed in their childhood period. have lot of bonding
          to the temple and trust and belief on the deity of that temple.
          Several people with their devotion have been donating to develop and
          maintain the temple they visited most in their childhood. However,
          today several temples in Villages, Mandal Head quarters, Towns also
          lack basic maintenance and needing such HELP/SUPPORT.
          <br /> <br />
          Based on some requests received, Endowments Dept has initiated a{" "}
          <b>New Scheme – Maa Vooru Maa Gudi</b> which facilitates those kind of
          activities to renovate/ improve/ rebuild the existing temples in any
          village or town by any Individual or Group of people.{" "}
        </p>
        <div className="top" style={{ background: "white" }}>
          <h1>The Broad Guidelines for Maa Vooru Maa Gudi scheme </h1>
          <img src={SD_divider} alt="-" style={{ height: "20px" }}></img>
        </div>
        <br /> <br />
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img
            className="img-thumbnail"
            src={maaooru2}
            alt="img"
            style={{ maxWidth: "450px" }}
          />
        </div>{" "}
        <br />
        <ul style={{ fontSize: "15px" }}>
          <li>
            Individuals or groups shall identify a temple in their village or
            selected place and become a catalyst to raise money, get the repairs
            done, get the temple opened with a minimum maintenance cost.{" "}
          </li>
          <li>
            Donor or Donors shall develop their own Plan of action to revive,
            improve, develop the temple in 6-12 months. Endowments Dept will
            record the details of the identified temple and team of people being
            involved with a contact person and allow the team to renovate/
            improve the temple and also to allow the temple management with
            little supervision.{" "}
          </li>
          <li>
            The Person/ Team shall ensure that identified Gudi is repaired/
            rebuilt / developed/ white washed/coloured etc and also be
            maintained with every day opening and minimum Doopa deepa
            Naivedhyams etc are performed.
          </li>
          <li>
            The team has no rights to sell any property etc with out the proper
            reasoning, detailed study and approval by the Commissioner’s office.
          </li>
          <li>
            Endowment Dept functions as a Supervisor or Guide and allow the
            Individual or Group to manage and maintain the temple.
          </li>

          <li>
            Endowments dept will recognize the Individuals and offer Merit
            Certificates and Medals for a successful completion.
          </li>
        </ul>
        <br />
        <div style={{ display: "flex", justifyContent: "center" }}>
          <a
            href="https://aped-tms.fra1.digitaloceanspaces.com/production-public-uploads/543e6848-9451-40bf-a7d0-366d705d4dd5-MaaVooru_MaGudi.pdf"
            className="sd-btn-orange"
            download={"maa-ooru.pdf"}
          >
            Download
          </a>
        </div>
      </div>
      <Sd_faqs_semi_view from="pratyaksha_seva" />
    </div>
  );
};

export default withRouter(TmsMaaOoruMaaGudi);
