import React, { useState, useEffect, useRef } from "react";
import {
  SD_cart_icon,
  // SD_Print_a_ticket_icon,
  SD_Dropdown_Arrow_icon,
  SD_Logo_circle,
  SD_Sign_In_icon,
  sd_minus_icon,
  sd_plus_icon,
  sd_default_profile_pic,
  sd_devotee_dashboard,
  sd_logout_icon,
  sd_booking_history_icon,
  sd_online_booking_icon,
  sd_support,
  SD_search_icon,
  twitter,
  facebook,
  youtube,
  insta,
  aptemplesLogo,
  public_dashboard_icon,
} from "../../Assets/imageList";
import "../../Utils/sd-styles/sd-navbar.scss";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { SD_Context } from "../../context/sd-context";
import Sd_mobile_left_sider from "./sd-mobile-left-sider";
import NavBarData from "../../Utils/data/navbar.data";
import TmsTempleOverlay from "./tms-temple-overlay";
import HeaderData from "../../Utils/data/header.data";
// import {toast} from "react-toastify";

const New_Nav = React.memo(({ history }) => {
  const {
    setActiveTopHeader,
    activeTopHeader,
    userData,
    language,
    setUserData,
    setCurrentDashboardLower,
    currentDashbaordLower,
    logout,
    setlanguage,
    languageList,
  } = React.useContext(SD_Context);

  //-----------------------------------states-------------------------------------------//
  const [templeSearch, setTempleSearch] = useState("");
  const [sider, setSider] = useState(false);
  const [rightsider, setrightSider] = useState(false);
  const [drop1, setDropOne] = useState(false);
  const [drop2, setDropTwo] = useState(false);
  const [drop3, setDropThree] = useState(false);
  const [visibleBut, setVisibleBut] = useState(false);
  const [drop4, setDropFour] = useState(false);
  const [drop5, setDropFive] = useState(false);
  const [drop6, setDropSix] = useState(false);
  const [drop7, setDropSeven] = useState(false);
  const [drop8, setDropEight] = useState(false);
  const [drop9, setDropNine] = useState(false);
  const [drop10, setDropTen] = useState(false);
  const [drop11, setDrop11] = useState(false);
  const [drop12, setDrop12] = useState(false);
  const [drop13, setDrop13] = useState(false);
  const [drop14, setDrop14] = useState(false);
  const [drop15, setDrop15] = useState(false);

  const [donationDrop, setDonationDrop] = useState("none");

  const refsevas = useRef(null);
  const refonlinebooking = useRef(null);
  const refmediaroom = useRef(null);
  const refsupport = useRef(null);
  const [dropRightone, setDropRightOne] = useState(false);
  const [dropRightTwo, setDropRightTwo] = useState(false);

  const refContainer = useRef();
  const [time, setTime] = React.useState(
    moment().utcOffset("+5:30").format(`dddd, DD MMMM YYYY - hh:mm A `)
  );

  //-----------------------------------states-------------------------------------------//

  //-----------------------------------useeffects---------------------------------------//

  useEffect(() => {
    const id = setInterval(timer, 60000);
    return () => clearInterval(id);
  }, [time]);

  useEffect(() => {
    if (document.getElementById("myDIV").scrollTop > 1000) {
      setVisibleBut(true);
    } else {
      setVisibleBut(false);
    }
  });

  useEffect(() => {
    if (history?.location?.pathname?.split?.("/")?.[2] === "temples") {
      setCurrentDashboardLower("temples");
    } else if (history?.location?.pathname?.split?.("/")?.[2] === "home") {
      setCurrentDashboardLower("home");
    } else if (
      history?.location?.pathname?.split?.("/")?.[2] === "sevas-and-darshanam"
    ) {
      setCurrentDashboardLower("Sevas&darshanam");
    } else if (history?.location?.pathname?.split?.("/")?.[2] === "donations") {
      setCurrentDashboardLower("Donations");
    } else if (
      history?.location?.pathname?.split?.("/")?.[2] === "online-booking"
    ) {
      setCurrentDashboardLower("online-booking");
    } else if (history?.location?.pathname?.split?.("/")?.[2] === "support") {
      setCurrentDashboardLower("Support");
    } else {
      setCurrentDashboardLower("");
    }
  }, [history?.location?.pathname]);

  // useEffect(() => {
  //   refContainer.current.scrollIntoView({
  //     behavior: "smooth",
  //     block: "start",
  //   });
  // }, [history?.location?.pathname]);

  //-----------------------------------useeffects-------------------------------------------//
  const timer = () => {
    let frist = moment()
      .utcOffset("+5:30")
      .format(`dddd, DD MMMM YYYY - hh:mm A `);
    // console.log(frist);
    setTime(frist + "IST");
  };
  const closeModal = () => {
    let documents = document.getElementById("tms-overlay");
    if (documents.style.display === "block") documents.style.display = "none";
  };
  return (
    <nav className="sd-navbar-custom" ref={refContainer}>
      <div className="sd-top-nav">
        <div className="sd-top-nav-left">
          <img
            src={aptemplesLogo}
            alt="logo"
            height="80%"
            onClick={() => {
              history?.push(`/${language?.code}/home`);
            }}
          />
        </div>
        <div className="sd-top-nav-right">
          <div className="sd-tnl-top">
            <div className="social-handles ml-10">
              <img
                onClick={() => {
                  window.open(
                    "https://www.facebook.com/sriveeravenkatasatyanarayanaswamy",
                    "_blank"
                  );
                }}
                src={facebook}
                alt="icon"
              />
              <img
                onClick={() => {
                  window.open(
                    "https://twitter.com/AnnavaramDevas1?t=CMCZDtumNhJOWCcXQL26JQ&s=09",
                    "_blank"
                  );
                }}
                src={twitter}
                alt="icon"
              />

              <img
                onClick={() => {
                  window.open(
                    "https://youtube.com/c/AnnavaramDevasthanamLive",

                    "_blank"
                  );
                }}
                src={youtube}
                alt="icon"
              />
              <img
                onClick={() => {
                  window.open(
                    "https://www.facebook.com/sriveeravenkatasatyanarayanaswamy",
                    "_blank"
                  );
                }}
                src={insta}
                alt="icon"
              />
            </div>
            <div className="sd-dropdown-language">
              <button className="sd-dropbtn-language" type="button">
                {language.desc}
              </button>
              <div
                className="sd-dropdown-content-language right-0"
                aria-labelledby="dropdownMenuButton1"
              >
                {languageList?.map((item, i) => {
                  return (
                    <a
                      key={i}
                      className="dropdown-item"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setlanguage(item);
                        history?.push(
                          `${history?.location?.pathname?.replace(
                            history?.location?.pathname.split?.("/")?.[1],
                            item?.code
                          )}${history?.location?.search}`
                        );
                        closeModal();
                      }}
                    >
                      {item?.desc}
                    </a>
                  );
                })}
              </div>
            </div>
            <div
              className={
                activeTopHeader !== "public-dashboard"
                  ? "sd-inner-div sd-w-24"
                  : "sd-inner-div sd-w-24 "
              }
              onClick={() => {
                history?.push(`/${language?.code}/devotee/public-dashboard`);
                setActiveTopHeader("public-dashboard");
              }}
            >
              <img src={public_dashboard_icon} alt="icon" />
              PUBLIC DASHBOARD
            </div>
          </div>
          <div className="sd-tnl-bottom">
            <div className="sd-tnlb-top">
              <input
                placeholder="Search Temple..."
                value={templeSearch}
                onFocus={() => {
                  document.getElementById("tms-overlay").style.display =
                    "block";
                }}
                onChange={(e) => setTempleSearch(e.target.value)}
                // onBlur={() => {
                //   if (templeSearch !== "") {
                //     return;
                //   } else {
                //     document.getElementById("tms-overlay").style.display =
                //       "none";
                //   }
                // }}
              />{" "}
              <img src={SD_search_icon} alt="Image" />
            </div>
            <button
              className={
                activeTopHeader !== "login"
                  ? "sd-tnlb-button"
                  : "sd-tnlb-button sd-selected"
              }
            >
              {" "}
              <img src={SD_Sign_In_icon} alt="icon" height={"20px"} />{" "}
              {localStorage.getItem("accessToken") && userData?.displayName ? (
                <div className="sd-dropdown-signin ">
                  <span
                    style={{ marginBottom: "2px" }}
                    id="dropdownMenuButton7"
                  >
                    {" "}
                    {userData?.displayName !== "" ||
                    userData?.displayName !== null
                      ? userData?.displayName
                      : "User"}
                  </span>
                  <div
                    className="sd-dropdown-content-signin"
                    aria-labelledby="dropdownMenuButton7"
                  >
                    <p
                      onClick={() => {
                        history.push(
                          `/${language?.code}/devotee-app/devotee-dashboard`
                        );
                        closeModal();
                      }}
                    >
                      Dashboard
                    </p>
                    <p
                      onClick={() => {
                        logout();
                        document.getElementById("tms-overlay").style.display =
                          "none";
                        history.push(`/${language?.code}/home`);
                      }}
                    >
                      Logout
                    </p>
                  </div>
                  <img
                    src={SD_Dropdown_Arrow_icon}
                    alt="^"
                    style={{ height: "7px", paddingLeft: "5px" }}
                  />
                </div>
              ) : (
                <div
                  style={{ color: "white", fontWeight: "600" }}
                  onClick={() => {
                    closeModal();
                    history.push(
                      `/${language?.code}/devotee/signin?to=devotee-app/devotee-dashboard`
                    );
                    setActiveTopHeader("login");
                  }}
                >
                  DEVOTEE SIGN IN
                </div>
              )}
            </button>
          </div>
        </div>
      </div>
      <div className="sd-bottom-nav">
        <nav className="sd-inner-navbar">
          <div
            className="sd-hamburger-icon"
            onClick={() => {
              if (rightsider) {
                setrightSider(false);
              }
              setSider(true);
            }}
          >
            <div></div>
            <div></div>
            <div></div>
          </div>

          <img
            className="mobile-logo"
            src={aptemplesLogo}
            height="40px"
            onClick={() => {
              history?.push(`/${language?.code}`);
            }}
          />
          <div className={sider ? "sd-left-sider block" : "sd-left-sider none"}>
            <Sd_mobile_left_sider
              language={language?.code}
              drop1={drop1}
              drop2={drop2}
              setSider={setSider}
              setDropTwo={setDropTwo}
              setDropOne={setDropOne}
              drop3={drop3}
              setDropThree={setDropThree}
              drop4={drop4}
              setDropFour={setDropFour}
              drop5={drop5}
              setDropFive={setDropFive}
              drop6={drop6}
              setDropSix={setDropSix}
              drop7={drop7}
              setDropSeven={setDropSeven}
              drop8={drop8}
              setDropEight={setDropEight}
              drop9={drop9}
              setDropNine={setDropNine}
              drop10={drop10}
              setDropTen={setDropTen}
              drop11={drop11}
              drop12={drop12}
              setDrop11={setDrop11}
              setDrop12={setDrop12}
              drop13={drop13}
              setDrop13={setDrop13}
              drop14={drop14}
              setDrop14={setDrop14}
              drop15={drop15}
              setDrop15={setDrop15}
            />
          </div>

          <div className="sd-inner-right">
            <ul className="sd-top-list">
              <li className="item">
                <p
                  className="nav-link"
                  // onClick={() => {
                  //   if (localStorage.getItem("accessToken")) {
                  //     history?.push(
                  //       `/${language?.code}/devotee-app/archaka-application`
                  //     );
                  //   } else {
                  //     history?.push(
                  //       `/${language?.code}/devotee/signin?to=devotee-app/archaka-application`
                  //     );
                  //   }
                  //   closeModal();
                  // }}
                >
                  {HeaderData["volunteer"][language?.code]}
                </p>
              </li>
              <li className="item">
                <p
                  className="nav-link"
                  onClick={() => {
                    if (localStorage.getItem("accessToken")) {
                      history?.push(
                        `/${language?.code}/devotee-app/booking-history`
                      );
                    } else {
                      history?.push(
                        `/${language?.code}/devotee/signin?to=devotee-app/booking-history`
                      );
                    }
                    closeModal();
                  }}
                >
                  {HeaderData["printATicket"][language?.code]}
                </p>
              </li>
            </ul>
            <ul className="sd-bottom-list">
              <li
                className={
                  currentDashbaordLower === "temples"
                    ? "dropdown sd-first-button border-r active"
                    : "dropdown sd-first-button border-r"
                }
                onMouseEnter={() => {
                  setDonationDrop("temples");
                }}
                onMouseLeave={() => setDonationDrop("none")}
              >
                <button
                  className={
                    currentDashbaordLower === "temples"
                      ? "temples btn active"
                      : "btn"
                  }
                  type="button"
                  id="dropdownMenuButton5"
                  data-toggle="dropdown"
                  onClick={() => {
                    closeModal();
                    setCurrentDashboardLower("temples");
                    history?.push(`/${language?.code}/temples`);
                  }}
                >
                  {HeaderData["temples"][language?.code]}
                </button>
              </li>

              <li
                className={
                  currentDashbaordLower === "Sevas&darshanam"
                    ? "dropdown sd-first-button border-r active"
                    : "dropdown sd-first-button border-r"
                }
                onMouseEnter={() => {
                  setDonationDrop("Sevas&darshanam");
                }}
                onMouseLeave={() => setDonationDrop("none")}
              >
                <button
                  type="button"
                  id="dropdownMenuButton2"
                  data-toggle="dropdown"
                  className={
                    currentDashbaordLower === "Sevas&darshanam"
                      ? "btn active"
                      : "btn"
                  }
                >
                  {HeaderData["sevas"][language?.code]}
                </button>
                {donationDrop === "Sevas&darshanam" && (
                  <div className="dropdown-menu" ref={refsevas}>
                    {" "}
                    <p
                      style={{ color: "black" }}
                      onClick={() => {
                        closeModal();
                        setDonationDrop("none");
                        history?.push(`/${language?.code}/sevas-and-darshanam`);
                      }}
                    >
                      {HeaderData["overview"][language?.code]}
                    </p>
                    <p
                      style={{ color: "black" }}
                      onClick={() => {
                        closeModal();
                        setDonationDrop("none");
                        history?.push(
                          `/${language?.code}/sevas-and-darshanam/darshanam`
                        );
                      }}
                    >
                      {HeaderData["darshanam"][language?.code]}
                    </p>
                    <p
                      style={{ color: "black" }}
                      onClick={() => {
                        closeModal();
                        setDonationDrop("none");
                        history?.push(
                          `/${language?.code}/sevas-and-darshanam/pratyaksha-seva`
                        );
                      }}
                    >
                      {HeaderData["pratyakshaSeva"][language?.code]}
                    </p>
                    <p
                      style={{ color: "black" }}
                      onClick={() => {
                        closeModal();
                        setDonationDrop("none");
                        history?.push(
                          `/${language?.code}/sevas-and-darshanam/paroksha-seva`
                        );
                      }}
                    >
                      {HeaderData["parokshaSeva"][language?.code]}
                    </p>
                  </div>
                )}
              </li>

              <li
                className={
                  currentDashbaordLower === "Donations"
                    ? "dropdown sd-first-button border-r active"
                    : "dropdown sd-first-button border-r "
                }
                onMouseEnter={() => {
                  setDonationDrop("donation");
                }}
                onMouseLeave={() => setDonationDrop("none")}
              >
                <button
                  className={
                    currentDashbaordLower === "Donations" ? "btn active" : "btn"
                  }
                  onClick={() => {
                    closeModal();

                    history?.push(`/${language?.code}/donations`);
                    setCurrentDashboardLower("Donations");
                  }}
                >
                  {HeaderData["donation"][language?.code]}
                </button>

                {/* {donationDrop === "donation" && (
                  <div className="dropdown-menu" ref={refdonations}>
                    <p
                      className="dropdown-item"
                      onClick={() => {
                        history?.push(`/${language?.code}/donations`);
                        setCurrentDashboardLower("Donations");
                        setDonationDrop(false);
                      }}
                    >
                      Overview
                    </p>
                    {NavBarData("donation")?.map((data, i) => {
                      return (
                        <p
                          className="dropdown-item"
                          key={i}
                          onClick={() => {
                            toast.info(
                              "Donations info is not available at the moment."
                            );
                            history?.push(
                              `/${language?.code}/about/general-information/${data?.path}`
                            );
                            setDonationDrop(false);
                          }}
                        >
                          {data?.name}
                        </p>
                      );
                    })}
                  </div>
                )} */}
              </li>

              <li
                className={
                  currentDashbaordLower === "online-booking"
                    ? "dropdown sd-first-button border-r active "
                    : "dropdown sd-first-button border-r"
                }
                onMouseEnter={() => {
                  setDonationDrop("online-booking");
                }}
                onMouseLeave={() => setDonationDrop("none")}
              >
                <button
                  className={
                    currentDashbaordLower === "online-booking"
                      ? "btn active"
                      : "btn"
                  }
                  type="button"
                  data-toggle="dropdown"
                  onClick={() => {
                    closeModal();
                    setDonationDrop("none");
                    history?.push(`/${language?.code}/online-booking`);
                  }}
                >
                  {HeaderData["onlineBooking"][language?.code]}
                </button>
                {donationDrop === "online-booking" && (
                  <div className="dropdown-menu " ref={refonlinebooking}>
                    <p
                      className="dropdown-item"
                      onClick={() => {
                        closeModal();

                        setDonationDrop("none");
                        history?.push(`/${language?.code}/online-booking`);
                      }}
                    >
                      {HeaderData["overview"][language?.code]}
                    </p>

                    {NavBarData("online-data")?.map((data, i) => {
                      return (
                        <p
                          key={i}
                          className="dropdown-item"
                          onClick={() => {
                            closeModal();

                            setDonationDrop("none");

                            if (localStorage.getItem("accessToken")) {
                              history?.push(
                                `/${language?.code}/devotee-app/online-booking/${data?.path}`
                              );
                            } else {
                              history?.push(
                                `/${language?.code}/devotee/signin?to=devotee-app/online-booking/${data?.path}`
                              );
                            }
                          }}
                        >
                          {data?.name[language?.code]}
                        </p>
                      );
                    })}
                  </div>
                )}
              </li>

              <li
                className={
                  currentDashbaordLower === "Media-room"
                    ? "dropdown sd-first-button border-r active"
                    : "dropdown sd-first-button border-r"
                }
                onMouseEnter={() => {
                  setDonationDrop("media-room");
                }}
                onMouseLeave={() => setDonationDrop("none")}
              >
                <button
                  className={
                    currentDashbaordLower === "Media-room"
                      ? "btn active"
                      : "btn"
                  }
                  type="button"
                  id="dropdownMenuButton4"
                  data-toggle="dropdown"
                  onClick={() => {
                    history?.push(`/${language?.code}/media-room`);
                    setCurrentDashboardLower("media-room");
                  }}
                >
                  {HeaderData["mediaRoom"][language?.code]}
                </button>
                {donationDrop === "media-room" && (
                  <div
                    className="dropdown-menu sd-media"
                    aria-labelledby="dropdownMenuButton4"
                    ref={refmediaroom}
                  >
                    <p
                      className="dropdown-item"
                      onClick={() => {
                        closeModal();

                        setDonationDrop("none");
                        setCurrentDashboardLower("Media-room");
                        history?.push(`/${language?.code}/media-room`);
                      }}
                    >
                      {HeaderData["overview"][language?.code]}
                    </p>

                    <p
                      className="dropdown-item"
                      onClick={() => {
                        history?.push(
                          `/${language?.code}/media-room/media-kit`
                        );
                        closeModal();

                        setDonationDrop("none");
                      }}
                    >
                      {HeaderData["mediaKit"][language?.code]}
                    </p>
                    <p className="dropdown-item">
                      <p style={{ color: "black", padding: "0px" }}>
                        {HeaderData["gallery"][language?.code]}
                      </p>
                      <div className="side-hover sd-left-hover">
                        {NavBarData("media")?.["gallery"]?.map((data, i) => {
                          return (
                            <div
                              key={i}
                              className="dropdown-item"
                              onClick={() => {
                                closeModal();

                                setDonationDrop("none");

                                history?.push(
                                  `/${language?.code}/media-room/${data?.path}`
                                );
                              }}
                            >
                              {data?.name[language?.code]}
                            </div>
                          );
                        })}
                      </div>
                    </p>
                    <p
                      className="dropdown-item"

                      // href={`/${language?.code}/media-room/whats-new`}
                    >
                      {HeaderData["whatsNew"][language?.code]}{" "}
                      <div className="side-hover sd-left-hover">
                        {NavBarData("media")?.["whats-new"]?.map((data, i) => {
                          return (
                            <div
                              key={i}
                              className="dropdown-item"
                              onClick={() => {
                                closeModal();

                                setDonationDrop("none");

                                history?.push(
                                  `/${language?.code}/media-room/whats-new/${data?.path}`
                                );
                              }}
                            >
                              {data?.name[language?.code]}
                            </div>
                          );
                        })}
                      </div>
                    </p>
                    <p className="dropdown-item">
                      {HeaderData["press"][language?.code]}
                      <div className="side-hover sd-left-hover">
                        {NavBarData("media")?.["press"]?.map((data, i) => {
                          return (
                            <div
                              key={i}
                              className="dropdown-item"
                              onClick={() => {
                                closeModal();

                                setDonationDrop("none");

                                history?.push(
                                  `/${language?.code}/media-room/press/${data?.path}`
                                );
                              }}
                            >
                              {" "}
                              {data?.name[language?.code]}
                            </div>
                          );
                        })}
                      </div>
                    </p>
                    <p
                      className="dropdown-item"
                      onClick={() => {
                        history?.push(`/${language?.code}/media-room/rti-act`);

                        closeModal();

                        setDonationDrop("none");
                      }}
                    >
                      {HeaderData["RTI"][language?.code]}
                    </p>
                    <p
                      className="dropdown-item"
                      onClick={() => {
                        history?.push(`/${language?.code}/media-room/tenders`);

                        closeModal();

                        setDonationDrop("none");
                      }}
                    >
                      {HeaderData["tenders"][language?.code]}
                    </p>
                    <p
                      className="dropdown-item"
                      onClick={() => {
                        history?.push(
                          `/${language?.code}/media-room/announcements`
                        );

                        closeModal();

                        setDonationDrop("none");
                      }}
                    >
                      {HeaderData["announcements"][language?.code]}
                    </p>
                  </div>
                )}
              </li>

              <li
                className={
                  currentDashbaordLower
                    ? "dropdown sd-first-button border-r active"
                    : "dropdown sd-first-button border-r"
                }
                onMouseEnter={() => {
                  setDonationDrop("support");
                }}
                onMouseLeave={() => setDonationDrop("none")}
              >
                <button
                  className={
                    currentDashbaordLower === "Support" ? "btn active" : "btn"
                  }
                  type="button"
                  id="dropdownMenuButton5"
                  data-toggle="dropdown"
                  onClick={() => {
                    history?.push(`/${language?.code}/support`);
                    setCurrentDashboardLower("Support");
                  }}
                >
                  {HeaderData["support"][language?.code]}
                </button>
                {donationDrop === "support" && (
                  <div
                    className="dropdown-menu sd-left"
                    ref={refsupport}
                    // aria-labelledby="dropdownMenuButton"
                  >
                    <p
                      className="dropdown-item"
                      onClick={() => {
                        history?.push(`/${language?.code}/support`);
                        setCurrentDashboardLower;
                        closeModal();
                        ("Support");
                        setDonationDrop("none");
                      }}
                    >
                      {HeaderData["overview"][language?.code]}
                    </p>
                    {NavBarData("support")?.map((data, i) => {
                      return (
                        <p
                          key={i}
                          className="dropdown-item"
                          onClick={() => {
                            history?.push(
                              `/${language?.code}/support/${data?.path}`
                            );

                            closeModal();

                            setDonationDrop("none");
                          }}
                        >
                          {data?.name[language?.code]}
                        </p>
                      );
                    })}
                  </div>
                )}
              </li>
            </ul>
          </div>
          <div className="sd-user-slider">
            <img
              src={SD_Sign_In_icon}
              alt="icon"
              onClick={() => {
                if (userData) {
                  if (userData.displayName) {
                    if (sider) {
                      setSider(false);
                    }
                    setrightSider(true);
                  } else {
                    history.push(
                      `/${language?.code}/devotee/signin?to=devotee-app/devotee-dashboard`
                    );
                  }
                } else {
                  history.push(
                    `/${language?.code}/devotee/signin?to=devotee-app/devotee-dashboard`
                  );
                }
              }}
            />
          </div>
          <div
            className={
              rightsider ? "sd-right-sider block" : "sd-right-sider none"
            }
          >
            <div className="inner">
              <div className="inner-nav-top">
                <div className="left">
                  <img
                    className="logo"
                    src={SD_Logo_circle}
                    alt="icon"
                    onClick={() => {
                      if (history) history.push(`/${language?.code}/home`);
                    }}
                  />
                </div>
                <div className="right">
                  <img src={SD_cart_icon} alt={SD_cart_icon} />
                  <button
                    type="button"
                    onClick={() => {
                      setrightSider(false);
                    }}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              </div>
              <div className="inner-bottom">
                <div className="inner-bottom-t">
                  <div className="sd-dashboard-profile ml-0">
                    <div className={"profile white-bg"}>
                      <p style={{ marginBottom: "0px" }}>
                        {userData?.displayName === "" ||
                        userData?.displayName === null ? (
                          "User"
                        ) : (
                          <>
                            <span>
                              {" "}
                              {userData.displayName?.split(" ")[1] ? (
                                userData.displayName
                                  ?.split(" ")[0]
                                  ?.charAt(0)
                                  .toUpperCase()
                              ) : (
                                <>
                                  <span>
                                    {userData.displayName
                                      ?.split(" ")[0]
                                      ?.charAt(0)
                                      .toUpperCase()}
                                    {userData.displayName
                                      ?.split(" ")[0]
                                      ?.charAt(1)}
                                  </span>
                                </>
                              )}
                            </span>
                            <span style={{ marginLeft: "-2px" }}>
                              {userData.displayName?.split(" ")[1]?.charAt(0)}
                            </span>
                          </>
                        )}
                      </p>
                    </div>

                    {/* <img
                        className="sd-dashboard-icons"
                        src={sd_profile_icon_dashboard}
                      /> */}
                    <h2
                      className="sd-heading-18 float-right-ellipse c-voilet "
                      style={{ padding: "5px !important" }}
                    >
                      {userData?.displayName}
                    </h2>
                  </div>
                </div>
                <div className="inner-bottom-b">
                  <div
                    onClick={() => {
                      setrightSider(false);

                      history?.push(
                        `/${language?.code}/devotee-app/devotee-dashboard`
                      );
                    }}
                  >
                    <img src={sd_devotee_dashboard} alt="dashboard" />
                    Devotee Dashboard
                  </div>
                  {localStorage.getItem("accessToken") ? (
                    userData?.displayName ? (
                      <div className="sd-dropdown-signin ">
                        <button
                          className="sd-dropbtn-signin"
                          type="button"
                          id="dropdownMenuButton6"
                        >
                          <img
                            className="spcl"
                            src={
                              userData?.profilePic !== null
                                ? userData?.profilePic
                                : SD_Sign_In_icon
                            }
                            alt={SD_Sign_In_icon}
                          />{" "}
                          {userData?.displayName !== "" ||
                          userData?.displayName !== null
                            ? userData?.displayName
                            : "User"}
                        </button>
                        <div
                          className="sd-dropdown-content-signin"
                          aria-labelledby="dropdownMenuButton6"
                        >
                          <p
                            onClick={() => {
                              history.push(
                                `/${language?.code}/devotee-app/devotee-dashboard`
                              );
                            }}
                          >
                            Dashboard
                          </p>
                          {/* {userData?.role === "admin" && (
                      <p
                        onClick={() => {
                          history?.push(
                            `/${language?.code}/devotee-app/userview`
                          );
                        }}
                      >
                        User view
                      </p>
                    )} */}
                          <p
                            onClick={() => {
                              logout();
                              history.push(`/${language?.code}/home`);
                            }}
                          >
                            Logout
                          </p>
                        </div>
                      </div>
                    ) : (
                      <div
                        style={{ color: "white", fontWeight: "600" }}
                        onClick={() => {
                          history?.push(
                            `/${language?.code}/devotee/signin?to=devotee-app/devotee-dashboard`
                          );
                        }}
                      >
                        <img src={SD_Sign_In_icon} alt="icon" /> DEVOTEE SIGNIN
                      </div>
                    )
                  ) : (
                    <div
                      style={{ color: "white", fontWeight: "600" }}
                      onClick={() => {
                        history?.push(
                          `/${language?.code}/devotee/signin?to=devotee-app/devotee-dashboard`
                        );
                      }}
                    >
                      <img src={SD_Sign_In_icon} alt="icon" /> DEVOTEE SIGNIN
                    </div>
                  )}

                  <div
                    className="between"
                    onClick={() => {
                      if (dropRightone) {
                        setDropRightOne(false);
                      }
                      setDropRightTwo(!dropRightTwo);
                    }}
                  >
                    <div className="sd-padding-none">
                      <img src={sd_online_booking_icon} alt="online" /> Online
                      Booking
                    </div>
                    {dropRightTwo ? (
                      <img
                        src={sd_minus_icon}
                        alt="-"
                        onClick={() => {
                          setDropRightTwo(false);
                        }}
                      ></img>
                    ) : (
                      <img
                        src={sd_plus_icon}
                        onClick={() => {
                          setDropRightTwo(true);
                        }}
                      />
                    )}
                  </div>

                  <div
                    className={
                      dropRightTwo
                        ? "drop-right-one block"
                        : "drop-right-one none"
                    }
                  >
                    <div
                      className="dropdown-item"
                      onClick={() => {
                        setrightSider(false);

                        if (localStorage.getItem("accessToken")) {
                          history?.push(
                            `/${language?.code}/devotee-app/online-booking/darshanam`
                          );
                        } else {
                          history?.push(
                            `/${language?.code}/devotee/signin?to=devotee-app/online-booking/darshanam`
                          );
                        }
                      }}
                    >
                      Darshanam
                    </div>
                    <div
                      className="dropdown-item"
                      onClick={() => {
                        setrightSider(false);

                        if (localStorage.getItem("accessToken")) {
                          history?.push(
                            `/${language?.code}/devotee-app/online-booking/pratyaksha-seva`
                          );
                        } else {
                          history?.push(
                            `/${language?.code}/devotee/signin?to=devotee-app/online-booking/pratyaksha-seva`
                          );
                        }
                      }}
                    >
                      Pratyaksha Seva Booking
                    </div>
                    <div
                      className="dropdown-item"
                      onClick={() => {
                        setrightSider(false);
                        if (localStorage.getItem("accessToken")) {
                          history?.push(
                            `/${language?.code}/devotee-app/online-booking/paroksha-seva`
                          );
                        } else {
                          history?.push(
                            `/${language?.code}/devotee/signin?to=devotee-app/online-booking/paroksha-seva`
                          );
                        }
                      }}
                    >
                      Paroksha Seva Booking
                    </div>
                    <div
                      className="dropdown-item"
                      onClick={() => {
                        setrightSider(false);

                        if (localStorage.getItem("accessToken")) {
                          history?.push(
                            `/${language?.code}/devotee-app/online-booking/donations`
                          );
                        } else {
                          history?.push(
                            `/${language?.code}/devotee/signin?to=devotee-app/online-booking/donations`
                          );
                        }
                      }}
                    >
                      Donations
                    </div>
                    <div
                      className="dropdown-item"
                      onClick={() => {
                        setrightSider(false);

                        if (localStorage.getItem("accessToken")) {
                          history?.push(
                            `/${language?.code}/devotee-app/online-booking/accommodations`
                          );
                        } else {
                          history?.push(
                            `/${language?.code}/devotee/signin?to=devotee-app/online-booking/accommodations`
                          );
                        }
                      }}
                    >
                      Accommodation
                    </div>{" "}
                    <div
                      className="dropdown-item"
                      onClick={() => {
                        setrightSider(false);
                        history?.push(
                          `/${language?.code}/devotee-app/online-booking/publications`
                        );
                      }}
                    >
                      Publications
                    </div>
                  </div>

                  <div
                    onClick={() => {
                      setrightSider(false);

                      history?.push(
                        `/${language?.code}/devotee-app/booking-history`
                      );
                    }}
                  >
                    <img src={sd_booking_history_icon} alt="booking" /> Booking
                    History
                  </div>

                  <div
                    className="between"
                    onClick={() => {
                      if (dropRightTwo) {
                        setDropRightTwo(false);
                      }
                      setDropRightOne(!dropRightone);
                    }}
                  >
                    <div className="sd-padding-none">
                      <img src={sd_default_profile_pic} alt="Image"></img> My
                      Account
                    </div>
                    {dropRightone ? (
                      <img src={sd_minus_icon} alt="-" />
                    ) : (
                      <img src={sd_plus_icon} alt="+" />
                    )}
                  </div>

                  <div
                    className={
                      dropRightone ? "drop-one block" : "drop-one none"
                    }
                  >
                    <div
                      onClick={() => {
                        setrightSider(false);

                        history?.push(
                          `/${language?.code}/devotee-app/my-account/profile`
                        );
                      }}
                    >
                      My Profile
                    </div>
                    <div
                      onClick={() => {
                        setrightSider(false);

                        history?.push(
                          `/${language?.code}/devotee-app/my-account/change-password`
                        );
                      }}
                    >
                      Change Password
                    </div>
                    <div
                      onClick={() => {
                        setrightSider(false);

                        history?.push(
                          `/${language?.code}/devotee-app/my-account/devotee-masterlist`
                        );
                      }}
                    >
                      Devotee Master List
                    </div>
                    <div
                      onClick={() => {
                        setrightSider(false);

                        history?.push(
                          `/${language?.code}/devotee-app/my-account/favorites`
                        );
                      }}
                    >
                      My Favorites
                    </div>
                  </div>

                  <div
                    style={{ paddingBottom: "50px" }}
                    onClick={() => {
                      setrightSider(false);

                      history?.push(`/${language?.code}/support`);
                    }}
                  >
                    <img src={sd_support} alt="support" />
                    Support
                  </div>
                </div>
              </div>
              <div
                className="inner-footer"
                onClick={() => {
                  logout();
                  setUserData({});
                  setDropOne(false);
                  setDropTwo(false);
                  setDropRightOne(false);
                  setDropRightTwo(false);
                  setrightSider(false);
                  history.push("/");
                }}
              >
                <img src={sd_logout_icon} alt="Logout" height="15px" />
                <span style={{ marginLeft: "5px", color: "#991403" }}>
                  Logout
                </span>
              </div>
            </div>
          </div>
        </nav>
      </div>
      <button
        className={visibleBut ? "active-r" : "hide-r"}
        onClick={() => {
          setVisibleBut(false);
          // refContainer.current.scrollIntoView({
          //   behavior: "smooth",
          //   block: "start",
          // });
        }}
      >
        <img src={SD_Dropdown_Arrow_icon} alt="Image" />
      </button>
      <TmsTempleOverlay searchtemple={templeSearch} language={language?.code} />
    </nav>
  );
});

New_Nav.displayName = "navbar";
export default withRouter(New_Nav);
