const NavBarData = (key) => {
  const aboutData = {
    "the-temple": [
      {
        name: `About Sri Annavaram Devasthanam`,
        path: `about-sri-annavaram-devasthanam`,
      },
      { name: "Main Temple", path: "main-temple" },
    ],
    "temple-story": [
      { name: "The History", path: "the-history" },
      { name: "Mythology", path: "mythology" },
      {
        name: `Sri Satyanarayana Swamy Vratha Katha`,
        path: "sri-satyanarayana-swamy-vratha-katha",
      },
      { name: "Topography", path: "topography" },
    ],
    "general-information": [
      { name: "Visiting Places", path: "visiting-places" },
      { name: "Adopted Temples", path: "adopted-temples" },
      { name: "Trusts", path: "trusts" },
      { name: "Trust Board", path: "trust-board" },
      { name: "Chairman", path: "chairman" },
      { name: "Executive Officer", path: "executive" },
      { name: "Do's & Don'ts", path: "dos-and-donts" },
      { name: "Intellectual Property", path: "intellectual-property" },
      { name: "Certifications", path: "certifications" },
    ],
  };

  const serviceDatashanamData = {
    pratyakshaseva: [
      { name: `Suprabhata Seva`, path: "sri-swamy-vari-vratham" },
      {
        name: `Sri Swamy vari Special Vratham`,
        path: "sri-swamy-vari-visishta-vratham",
      },
      { name: `Laksha Pathri Pooja`, path: "laksha-pathri-pooja" },
      { name: `Laksha Kumkumarchana`, path: "laksha-kumkumarchana" },
      { name: `Pratyangiri Homam`, path: "pratyangiri-homam" },
      {
        name: `Sri Swamy vari Nithya Kalyanam`,
        path: "sri-swamy-vari-nithya-kalyanam",
      },
      {
        name: `Sri Swamy vari Pavalimpu Seva`,
        path: "sri-swamy-vari-pavalimpu-seva",
      },
      { name: `Sri Swamy vari Abhishekam`, path: "sri-swamy-vari-abhishekam" },
      {
        name: `Sri Sita Ramula Pattabhishekam`,
        path: "sri-sita-ramula-pattabhishekam",
      },
      {
        name: `Sri Swamy vari Abhishekam - Makha Nakshatram`,
        path: "sri-swamy-vari-abhishekam",
      },
      { name: `Swarna Pushparchana`, path: "swarna-pushparchana" },
      { name: `Vedaseerwachanam`, path: "vedaseerwachanam" },
      { name: `Go Pooja`, path: "go-pooja" },
      { name: `Surya Namaskaramulu`, path: "surya-namaskaramulu" },
    ],
    parokshaseva: [
      {
        name: `Sri Satyanarayana Swamy vari Vratham`,
        path: "sri-satyanarayana-swamy-vari-vratham",
      },
      {
        name: `Sri Satyanarayana Swamy vari Kalyanam`,
        path: "sri-satyanarayana-swamy-vari-kalyanam",
      },
      { name: `Ayushya Homam`, path: "ayushya-homam" },
      { name: `Chandi Homam`, path: "chandi-homam" },
      { name: `Pratyangira Homam`, path: "pratyangira-homam" },
      {
        name: `Sri Kanaka Durga Amma vari Chandi Homam - Moola Nakshatram`,
        path: "sri-kanaka-durga-amma-vari-chandi-homam",
      },
      { name: `Surya Namaskaramulu`, path: "surya-namaskaramulu" },
      { name: `Sri Swamy vari Abhishekam`, path: "sri-swamy-vari-abhishekam" },
      { name: `Vedseerwachanam`, path: "Vedseerwachanam" },
    ],
    Darshanam: [
      { name: "Sri Swamy vari Darshanam", path: "sri-swamy-vari-darshanam" },
      {
        name: "Sri Swamy vari Seegra Darshanam",
        path: "sri-swamy-vari-seegra-darshanam",
      },
      {
        name: "Sri Swamy vari Yantralayam",
        path: "sri-swamy-vari-yantralayam",
      },
    ],
  };

  const donationsData = [
    { name: "e-Hundi", path: "e-hundi" },
    {
      name: "Sri Satya Deva Annaprasada Distribution Trust ",
      path: "sri-satya-deva-annaprasada-distribution-trust ",
    },
    {
      name: "Pulihora and Dadhojanam Scheme",
      path: "pulihora-and-dadhojanam-scheme",
    },
  ];

  const onlineBookingData = [
    {
      name: { "en-in": "Darshanam Tickets", "te-in": "దర్శనం టికెట్లు" },
      path: "darshanam",
    },
    {
      name: {
        "en-in": "Pratyaksha Seva Tickets",
        "te-in": "ప్రత్యక్ష సేవ టికెట్లు",
      },
      path: "pratyaksha-seva",
    },
    {
      name: {
        "en-in": "Paroksha Seva Booking",
        "te-in": "పరోక్ష సేవ టికెట్లు",
      },
      path: "paroksha-seva",
    },
    // { name: "Prasadam", path: "Prasadam" },
    {
      name: { "en-in": "Accommodation", "te-in": "వసతి టికెట్లు" },
      path: "Accommodations",
    },
    // { name: "Kalyana Katta Tickets", path: "kalyanakatta" },
    // { name: "Annadanam Tickets", path: "annadanam" },
    // { name: "Kalyana Mandapam Booking", path: "kalyana-mandapam" },
  ];

  const supportData = [
    {
      name: { "en-in": "FAQs", "te-in": "తరచుగా ఉండే ప్రశ్నలు" },
      path: "faqs-all",
    },
    // {
    //   name: {
    //     "en-in": "Facilities to Pilgrims",
    //     "te-in": "భక్తులకు సౌకర్యాలు",
    //   },
    //   path: "facilities-to-pilgrims",
    // },
    // {
    //   name: { "en-in": "Connectivity", "te-in": "దేవాలయ మార్గాలు" },
    //   path: "connectivity",
    // },
    // { name: { "en-in": "Live Chat", "te-in": "లైవ్ చాట్" }, path: "live-chat" },
    {
      name: { "en-in": "Contact Us", "te-in": "సంప్రదించండి" },
      path: "contact-us",
    },
  ];

  const mediaData = {
    gallery: [
      {
        name: { "en-in": `Photo Gallery`, "te-in": "Photo Gallery" },
        path: `photo-gallery`,
      },
      {
        name: { "en-in": "Video Gallery", "te-in": "Video Gallery" },
        path: "video-gallery",
      },
    ],
    "whats-new": [
      {
        name: { "en-in": "Temple Diary", "te-in": "Temple Diary" },
        path: "temple-diary",
      },
      { name: { "en-in": "Events", "te-in": "Events" }, path: "events" },
      {
        name: { "en-in": `Newsletter`, "te-in": "న్యూస్ లెటర్" },
        path: "newsletter",
      },
    ],
    press: [
      {
        name: { "en-in": "Temple News", "te-in": "టెంపుల్ న్యూస్" },
        path: "temple-news",
      },
      {
        name: { "en-in": "Press Releases", "te-in": "Press Releases" },
        path: "press-releases",
      },
      {
        name: { "en-in": "Press Coverage", "te-in": "Press Coverage" },
        path: "press-coverage",
      },
      {
        name: { "en-in": "Media Queries", "te-in": "Media Queries" },
        path: "media-queries",
      },
    ],
  };

  const dataMap = {
    about: aboutData,
    "seva-darshanam": serviceDatashanamData,
    donation: donationsData,
    "online-data": onlineBookingData,
    media: mediaData,
    support: supportData,
  };

  return dataMap[key];
};

export default NavBarData;
