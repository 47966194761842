import React, { useContext } from "react";
import { SD_divider } from "../../Assets/imageList";
import SD_media_room_div_component from "../../Components/body/sd-support/sd-support-div-component";
import "../../Utils/sd-styles/sd-support.scss";
import Sd_breadcrumb from "../../Components/sd-common-components/sd-breadcrumb";
import Sd_faqs_semi_view from "../../Components/sd-common-components/sd-faqs-semi-view";
import Sd_gallery_semi_view from "../../Components/sd-common-components/sd-gallery-semi-view";
import supportData from "../../Utils/data/support.data";
import { SD_Context } from "../../context/sd-context";

const Sd_support = () => {
  const { language } = useContext(SD_Context);

  return (
    <>
      <Sd_breadcrumb from="Support"></Sd_breadcrumb>
      <div className="sd-support-main">
        <div className="sd-support-top">
          <h1>
            <span></span> {`${supportData?.title[language?.code]}`}
          </h1>
          <img
            src={SD_divider}
            alt={SD_divider}
            style={{ height: "20px" }}
          ></img>
        </div>
        <div className="sd-support-bottom">
          <SD_media_room_div_component
            type="FAQs"
            path="devotee-app/support/"
          />
          {/* <SD_media_room_div_component
            type="Facilities to Pilgrims"
            path="devotee-app/support/"
          /> */}
          {/* <SD_media_room_div_component
            type="Connectivity"
            path="devotee-app/support/"
          /> */}
          {/* <SD_media_room_div_component
            type="Call Center"
            path="devotee-app/support/"
          />{" "}
          <SD_media_room_div_component
            type="Live Chat"
            path="devotee-app/support/"
          /> */}
          <SD_media_room_div_component
            type="Contact Us"
            path="devotee-app/support/"
          />
        </div>
      </div>
      <Sd_faqs_semi_view from="support" />
      <Sd_gallery_semi_view />
    </>
  );
};
export default Sd_support;
