import React, { useContext } from "react";
import { withRouter } from "react-router-dom";
// import { toast } from "react-toastify";
import { SD_Right_Arrow_white } from "../../../Assets/imageList";
import { SD_Context } from "../../../context/sd-context";
import supportData from "../../../Utils/data/support.data";
import commonData from "../../../Utils/data/common.data";
// import IndiaTime from "../../../Utils/sd-moment/IndiaTime";

const SD_support_div_component = ({ type, history }) => {
  //-------------------------------------------------------------states-----------------------------------------------------------//
  const { language } = useContext(SD_Context);

  //-------------------------------------------------------------states-----------------------------------------------------------//

  //-------------------------------------------------------------useffect-----------------------------------------------------------//

  //-------------------------------------------------------------useffect-----------------------------------------------------------//
  return (
    <div
      className="sd-support-div"
      onClick={() => {
        if (type === "FAQs") {
          history?.push(`/${language?.code}/support/faqs-all`);
        } else if (type === "Contact Us") {
          history?.push(`/${language?.code}/support/contact-us`);
        }
        // else if (type === "Connectivity") {
        //   history?.push(`/${language?.code}/support/connectivity`);
        // }
        else if (type === "Facilities to Pilgrims") {
          history?.push(`/${language?.code}/support/facilities-to-pilgrims`);
        }
      }}
    >
      <img
        className={
          type === "Facilities to Pilgrims"
            ? "head-image sd-spcl"
            : "head-image"
        }
        src={supportData[type]?.image_url}
        alt={supportData[type]?.image_url}
      />
      <h4>{supportData[type]?.title[language?.code]}</h4>
      <h5>{supportData[type]?.description[language?.code]}</h5>
      <h6 style={{ cursor: "pointer" }}>
        {commonData?.viewMore[language?.code]}{" "}
        <img src={SD_Right_Arrow_white} alt="Image" />
      </h6>
      {/* <button
        onClick={() => {
          //   if (localStorage.getItem("accessToken")) {
          //     history?.push(`/${language?.code}/${path}`);
          //   } else {
          //     history?.push(`/${language?.code}/devotee/signin?to=${path}`);
          //   }
        }}
      >
        {commonData?.viewMore[language?.code]}{" "}
        <img className="imgs" src={SD_Right_Arrow_white} alt="images" />
      </button> */}
    </div>
  );
};
export default withRouter(SD_support_div_component);
