import React from "react";
import { withRouter } from "react-router";
import "../../Utils/sd-styles/sd-faqs.scss";
import Sd_gallery_semi_view from "../../Components/sd-common-components/sd-gallery-semi-view";
import SD_Paroksha_Seva_One from "../body/paroksha-seva/sd-paroksha-seva-component-one";
import { Helmet } from "react-helmet";
// import {
//   call_black,
//   email_black,
//   SD_Logo_circle,
// } from "../../Assets/imageList";
import CommingSoon from "./comming-soon";
const Sd_faqs_all = () => {
  //-------------------------------------------------------------states-----------------------------------------------------------//

  //-------------------------------------------------------------states-----------------------------------------------------------//

  //-------------------------------------------------------------states-----------------------------------------------------------//

  //-------------------------------------------------------------states-----------------------------------------------------------//
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>FAQs at AP Temples | AP temples </title>
        <meta name="description" content="FAQs of AP Temples." />
        <meta name="keywords" content="FAQs of AP Temples." />
      </Helmet>
      <SD_Paroksha_Seva_One from="faqs" />
      {/* <div className="sd-top">
        <h1>
          <span></span> {"FAQs"}
        </h1>
        <img src={SD_divider} alt={SD_divider} style={{ height: "20px" }}></img>
      </div>

      <div className="small-divs-main">
        <div
          className="small-divs"
          onClick={() => {
            history?.push(`/${language?.code}/faqs/paroksha_seva`);
          }}
        >
          Paroksha Seva
        </div>
        <div
          className="small-divs"
          onClick={() => {
            history?.push(`/${language?.code}/faqs/darshanam`);
          }}
        >
          Darshanam
        </div>
     
        <div
          className="small-divs"
          onClick={() => {
            history?.push(`/${language?.code}/faqs/saswata_seva`);
          }}
        >
          Saswata Seva
        </div>
        <div
          className="small-divs"
          onClick={() => {
            history?.push(`/${language?.code}/faqs/darshanam`);
          }}
        >
          About
        </div>
        <div
          className="small-divs"
          onClick={() => {
            history?.push(`/${language?.code}/faqs/online_accomadation`);
          }}
        >
          Accommodation
        </div>
        <div
          className="small-divs"
          onClick={() => {
            history?.push(`/${language?.code}/faqs/online_booking`);
          }}
        >
          Online Booking
        </div>
        <div
          className="small-divs"
          onClick={() => {
            history?.push(`/${language?.code}/faqs/volunteer`);
          }}
        >
          Volunteer
        </div>
     
        <div
          className="small-divs"
          onClick={() => {
            history?.push(`/${language?.code}/faqs/anna_prasadam_trust`);
          }}
        >
          AnnaPrasadam trust
        </div>
        <div
          className="small-divs"
          onClick={() => {
            history?.push(`/${language?.code}/faqs/donations`);
          }}
        >
          Donations
        </div>
        <div
          className="small-divs"
          onClick={() => {
            history?.push(`/${language?.code}/faqs/e_hundi`);
          }}
        >
          e-Hundi
        </div>
        <div
          className="small-divs"
          onClick={() => {
            history?.push(`/${language?.code}/faqs/go_samrakshana_trust`);
          }}
        >
          GoSamrakhsana Trust
        </div>
        <div
          className="small-divs"
          onClick={() => {
            history?.push(`/${language?.code}/faqs/pranadhana_trust`);
          }}
        >
          Praanadhana Trust
        </div>
        <div
          className="small-divs"
          onClick={() => {
            history?.push(`/${language?.code}/faqs/SBMSD_trust`);
          }}
        >
          SBMSD Trust
        </div>
        <div
          className="small-divs"
          onClick={() => {
            history?.push(`/${language?.code}/faqs/signin_up`);
          }}
        >
          Login/Register
        </div>
        <div
          className="small-divs"
          onClick={() => {
            history?.push(`/${language?.code}/faqs/swacha_srisailam`);
          }}
        >
          Swachh Srisailam
        </div>
        <div
          className="small-divs"
          onClick={() => {
            history?.push(`/${language?.code}/faqs/park_ganapthi_homam`);
          }}
        >
          Ganapathi Homam (Paroksha Seva)
        </div>
        <div
          className="small-divs"
          onClick={() => {
            history?.push(`/${language?.code}/faqs/prak_chandi_homam`);
          }}
        >
          Chandi Homam (Paroksha Seva)
        </div>{" "}
        <div
          className="small-divs"
          onClick={() => {
            history?.push(`/${language?.code}/faqs/park_kumkumarchana`);
          }}
        >
          Kumkumarchana (Paroksha Seva)
        </div>{" "}
        <div
          className="small-divs"
          onClick={() => {
            history?.push(`/${language?.code}/faqs/park_leela_kalyanotsavam`);
          }}
        >
          Leela Kalyanotsavam (Paroksha Seva)
        </div>{" "}
        <div
          className="small-divs"
          onClick={() => {
            history?.push(`/${language?.code}/faqs/park_mrityunkjaya`);
          }}
        >
          Mrityunjaya Homam (Paroksha Seva)
        </div>{" "}
        <div
          className="small-divs"
          onClick={() => {
            history?.push(`/${language?.code}/faqs/park_rudra_homam`);
          }}
        >
          Rudra Homam (Paroksha Seva)
        </div>{" "}
        <div
          className="small-divs"
          onClick={() => {
            history?.push(`/${language?.code}/faqs/park_rudra_abhishekam`);
          }}
        >
          Rudra Abhishekam (Paroksha Seva)
        </div>{" "}
        <div
          className="small-divs"
          onClick={() => {
            history?.push(`/${language?.code}/faqs/park_valli_devasena`);
          }}
        >
          Valli Devasena Subramanya Swamy Kalyanam (Paroksha Seva)
        </div>{" "}
        <div
          className="small-divs"
          onClick={() => {
            history?.push(`/${language?.code}/faqs/park_vedasirvachanam`);
          }}
        >
          Vedaseervachanam (Paroksha Seva)
        </div>{" "}
        <div
          className="small-divs"
          onClick={() => {
            history?.push(`/${language?.code}/faqs/support`);
          }}
        >
          Support
        </div>{" "}
        {/* <div
          className="small-divs"
          onClick={() => {
            history?.push(`/${language?.code}/faqs/park_ekantha_Seva`);
          }}
        >
          Ekantha Seva (Paroksha Seva)
        </div> */}
      {/* </div>  */}
      <CommingSoon />
      <Sd_gallery_semi_view />
    </>
  );
};
export default withRouter(Sd_faqs_all);
