const HeaderData = {
  temples: { "en-in": "Temples", "te-in": "దేవాలయాలు" },
  sevas: { "en-in": "Sevas & Darshanam", "te-in": "సేవ మరియు దర్శనం" },
  donation: { "en-in": "Donations", "te-in": "విరాళాలు" },
  onlineBooking: { "en-in": "Online Booking", "te-in": "ఆన్లైన్ బుకింగ్" },
  mediaRoom: { "en-in": "Media Room", "te-in": "మీడియా రూమ్" },
  support: { "en-in": "Support", "te-in": "సపోర్ట్" },
  volunteer: { "en-in": "Volunteer", "te-in": "వాలంటీర్" },
  archaka: { "en-in": "Archaka Exam", "te-in": "అర్చక పరీక్షలు" },
  printATicket: { "en-in": "Print a Ticket", "te-in": "ప్రింట్ టికెట్" },
  overview: { "en-in": "Overview", "te-in": "Overview" },
  darshanam: { "en-in": "Darshanam", "te-in": "దర్శనం" },
  pratyakshaSeva: { "en-in": "Pratyaksha Seva", "te-in": "ప్రత్యక్ష సేవ" },
  parokshaSeva: { "en-in": "Paroksha Seva", "te-in": "పరోక్ష సేవ" },
  mediaKit: { "en-in": "Media Kit", "te-in": "మీడియా కిట్" },
  gallery: { "en-in": "Gallery", "te-in": "గ్యాలరీ" },
  press: { "en-in": "Press", "te-in": "ప్రెస్" },
  RTI: { "en-in": "RTI Act", "te-in": "సమాచార హక్కు చట్టం" },
  tenders: { "en-in": "Tenders", "te-in": "టెండర్లు " },
  announcements: { "en-in": "Announcements", "te-in": "ప్రకటనలు" },
  whatsNew: { "en-in": "Whats New", "te-in": "సరికొత్త సమాచారం " },
};

export default HeaderData;
