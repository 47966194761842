import React from "react";
import { withRouter } from "react-router-dom";
import {
  call_black,
  email_black,
  SD_Logo_circle,
} from "../../Assets/imageList";
import "../../Utils/sd-styles/sd-home.scss";
const Sd_comming_soon = () => {
  return (
    <div className="sd-coming-soon">
      <img src={SD_Logo_circle} alt="logo" height={"100px"} />
      <h4>
        AP Temples Portal
        <br /> Government Of Andhra Pradesh{" "}
      </h4>
      <br />
      <h2> Coming Soon</h2>
      <h4 style={{ fontSize: "20px" }}>Contact Us</h4>
      <p className="mb-0">
        <img src={email_black} alt="call" height={"12px"} className="mr-10 " />
        endow-edpsec@gov.in
      </p>
      <p className="mt-0">
        <img src={call_black} alt="call" height={"12px"} className="mr-10" />
        +91 - 098490 05495
        <br />
        (Mon-Sat, 9:00AM - 7:00PM)
      </p>
    </div>
  );
};
export default withRouter(Sd_comming_soon);
