import {
  sd_connectivity,
  sd_contact_us_icon,
  sd_facilities,
  sd_faq_icon,
} from "../../Assets/imageList";

const supportData = {
  title: { "en-in": "Support", "te-in": "సపోర్ట్" },
  FAQs: {
    title: { "en-in": "FAQ's", "te-in": "తరచుగా అడిగే ప్రశ్నలు" },
    description: {
      "en-in": `Devotees can get the answers of frequently asked questions by accessing the FAQs page. For the comfort of devotees, we provided FAQ’s for all the pages in our Sri Veera Venkata Satyanarayana Swamy vari Devasthanam.`,
      "te-in":
        "భక్తులకు సాధారణంగా వచ్చే సందేహాలు, వాటి జవాబులు తరచుగా అడిగే ప్రశ్నల పేజీలో చూడవచ్చు. భక్తుల సౌకర్యార్థం ఈ పేజి పొందు పరచడం జరిగింది.",
    },
    image_url: sd_faq_icon,
  },
  // "Facilities to Pilgrims": {
  //   title: { "en-in": "Facilities to Pilgrims", "te-in": "యాత్ర సౌకర్యాలు" },

  //   description: {
  //     "en-in": `Temple provides Swamy vari uchitha Prasadam, Annadanam, Information center and cloak room for devotees. People can make proper use of them.`,
  //     "te-in":
  //       "దేవస్థానానికి విచ్చేసే భక్తుల సౌకర్యార్థం, ఆలయంలో ఉచిత అన్నప్రసాదం, సమాచార కేంద్రం, వస్తువులు భద్రపరిచే కేంద్రం మొదలగు వాటిని ఏర్పాటు చేయడం జరిగింది.",
  //   },
  //   image_url: sd_facilities,
  // },
  // Connectivity: {
  //   title: { "en-in": "Connectivity", "te-in": "కనెక్టివిటీ" },

  //   description: {
  //     "en-in": `Annavaram has good connectivity, though it is a village. By the grace of Lord Sri Veera Venkata Satyanarayana Swamy proper Road, Railway and Air Transport facilities are available. The nearest Railway Station is Annavaram and nearest Airport is at Rajahmundry and Visakhapatnam.`,
  //     "te-in":
  //       "దేవస్థానానికి వెళ్లేందుకు పలు మార్గాలు ఈ కనెక్టివిటీ ద్వారా తెలుసుకోవచ్చు. రోడ్డు మార్గం, రైల్ మార్గం మరియు విమాన మార్గం సమాచారం ఇక్కడ లభిస్తుంది.",
  //   },
  //   image_url: sd_connectivity,
  // },
  // "Call Center": {
  //   description: `A spiritual glance of the divine power in the sanctum of a temple relieves the mind of burdens.
  // Experience the sacred power of Darshanam by booking your tickets today.`,
  //   image_url: sd_darshanam_widget,
  // },
  // "Live Chat": {
  //   description: `A spiritual glance of the divine power in the sanctum of a temple relieves the mind of burdens.
  // Experience the sacred power of Darshanam by booking your tickets today.`,
  //   image_url: sd_darshanam_widget,
  // },
  "Contact Us": {
    title: { "en-in": "Contact Us", "te-in": "సంప్రదించండి" },
    description: {
      "en-in": `To get complete information of the Temple and to clarify the doubts of devotees regarding Sevas, Darshanam, accommodation and other deeds of Sri Veera Venkata Satyanarayana Swamy vari Devasthanam, a call center is being organized by the Temple.
    `,
      "te-in":
        "దేవస్థానానికి సంభందించిన పూర్తి సమాచారం, సేవలు, దర్శనం మరియు ఇతర వివరాలు కోసం ఈమెయిల్ లేక ఫోన్ కి కాంటాక్ట్ చేయగలరు.",
    },
    image_url: sd_contact_us_icon,
  },
};

export default supportData;
