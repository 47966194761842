import React, { useState } from "react";
import "../../Utils/sd-styles/sd-home.scss";
import Values from "../../Components/body/values/values";
import SD_Paroksha_Seva_One from "../../Components/body/paroksha-seva/sd-paroksha-seva-component-one";
const Sd_Values = () => {
  const [visible, setVisible] = useState(true);

  return (
    <div className="w-100">
      <SD_Paroksha_Seva_One from="Values" />
      <Values visible={visible} setVisible={setVisible} />
    </div>
  );
};
export default Sd_Values;
