const FooterData = (key) => {
  const about = {
    title: { "en-in": "About", "te-in": "అబౌట్" },
    data: [
      { name: { "en-in": "Temples", "te-in": "దేవాలయాలు" }, path: "temples" },
      // { name: "Trust Boards", path: "trust-boards" },
      {
        name: { "en-in": "About department", "te-in": "దేవాదాయ ధర్మాదాయ శాఖ్" },
        path: "endownment-department",
      },
    ],
  };

  const values = {
    title: { "en-in": "Values", "te-in": "వాల్యూస్ " },
    data: [
      {
        name: {
          "en-in": "Hindu Dharmika Parishad",
          "te-in": "హిందూ ధర్మ పరిషత్",
        },
        path: "hindu-dharmika-parishad",
      },
      {
        name: {
          "en-in": "Veda and Agama Patashala",
          "te-in": "వేద మరియు ఆగమ పాఠశాల",
        },
        path: "veda-and-agama-patashala",
      },
      {
        name: { "en-in": "Common Good Fund", "te-in": "కామన్ గుడ్ ఫండ్" },
        path: "common-good-fund",
      },
      {
        name: {
          "en-in": "Dhoopa Deepa Naivedyam",
          "te-in": "ధూప దీప నైవేద్యం",
        },
        path: "dhoopa-deepa-naivedyam",
      },
      {
        name: {
          "en-in": "Archaka Welfare Fund",
          "te-in": "అర్చక సంక్షేమ నిధి",
        },
        path: "archaka-welfare-fund",
      },
      {
        name: {
          "en-in": "SITA",
          "te-in": "SITA - స్టేట్ ఇన్స్టిట్యూట్ ఆఫ్ టెంపుల్ అడ్మినిస్ట్రేషన్",
        },
        path: "sita",
      },
      // { name: "Founder Family Members", path: "founding-family-members" },
    ],
  };

  const temples = {
    title: { "en-in": "Temples", "te-in": "దేవాలయాల " },
    data: [
      {
        name: { "en-in": "Kanipakam", "te-in": "కాణిపాకం" },

        path: "SSVVSD",
      },
      {
        name: { "en-in": "Srikalahasthi", "te-in": "శ్రీకాళహస్తి" },
        path: "SKHTD",
      },
      {
        name: { "en-in": "Srisailam", "te-in": "శ్రీశైలం" },
        path: "SBMSD",
      },
      {
        name: { "en-in": "Mahanandi", "te-in": "మహానంది" },
        path: "SMESD",
      },
      {
        name: { "en-in": "Kasapuram", "te-in": "కసాపురం" },
        path: "SNASVD",
      },
      {
        name: { "en-in": "Vijayawada", "te-in": "విజయవాడ" },
        path: "SDMSD",
      },
      {
        name: { "en-in": "Dwaraka Tirumala", "te-in": "ద్వారకా తిరుమల" },
        path: "SVSVD",
      },
      {
        name: { "en-in": "Annavaram", "te-in": "అన్నవరం" },
        path: "SVVSSD",
      },
      {
        name: { "en-in": "Simhachalam", "te-in": "సింహాచలం" },
        path: "SVLNSD",
      },
      {
        name: {
          "en-in": "Sri Kanaka Mahalakshmi",
          "te-in": "శ్రీ కనక మహాలక్ష్మి",
        },
        path: "SKML",
      },
      {
        name: { "en-in": "Penuganchiprolu", "te-in": "పెనుగంచిప్రోలు" },
        path: "STADPPL",
      },
    ],
  };

  const onlineBooking = {
    title: { "en-in": "Online Booking", "te-in": "ఆన్లైన్ బుకింగ్" },
    data: [
      {
        name: { "en-in": "Darshanam Tickets", "te-in": "దర్శనం టికెట్లు" },
        path: "darshanam",
      },
      {
        name: {
          "en-in": "Pratyaksha Seva Tickets",
          "te-in": "ప్రత్యక్ష సేవ టికెట్లు",
        },
        path: "pratyaksha-seva",
      },
      {
        name: {
          "en-in": "Paroksha Seva Booking",
          "te-in": "పరోక్ష సేవ టికెట్లు",
        },
        path: "paroksha-seva",
      },
      // { name: "Prasadam", path: "Prasadam" },
      {
        name: { "en-in": "Accommodation", "te-in": "వసతి టికెట్లు" },
        path: "Accommodations",
      },
      // { name: "Kalyana Katta Tickets", path: "kalyanakatta" },
      // { name: "Annadanam Tickets", path: "annadanam" },
      // { name: "Kalyana Mandapam Booking", path: "kalyana-mandapam" },
    ],
  };

  const mediaRoom = {
    title: { "en-in": "Media Room", "te-in": "మీడియా రూమ్" },
    data: [
      {
        name: { "en-in": "Media Kit", "te-in": "మీడియా కిట్" },
        path: "media-kit",
      },
      {
        name: { "en-in": "Gallery", "te-in": "గ్యాలరీ" },
        path: "photo-gallery",
      },
      {
        name: { "en-in": "Press", "te-in": "ప్రెస్" },
        path: "press/press-releases",
      },
      { name: { "en-in": "Tenders", "te-in": "టెండర్లు" }, path: "tenders" },
      {
        name: { "en-in": "RTI Act", "te-in": "సమాచార హక్కు చట్టం" },
        path: "rti-act",
      },
      {
        name: { "en-in": "Announcements", "te-in": "ప్రకటనలు" },
        path: "announcements",
      },
    ],
  };

  const support = {
    title: { "en-in": "Support", "te-in": "సపోర్ట్" },
    data: [
      {
        name: { "en-in": "FAQs", "te-in": "తరచుగా ఉండే ప్రశ్నలు" },
        path: "faqs-all",
      },
      // {
      //   name: {
      //     "en-in": "Facilities to Pilgrims",
      //     "te-in": "భక్తులకు సౌకర్యాలు",
      //   },
      //   path: "facilities-to-pilgrims",
      // },
      // {
      //   name: { "en-in": "Connectivity", "te-in": "దేవాలయ మార్గాలు" },
      //   path: "connectivity",
      // },
      // {
      //   name: { "en-in": "Live Chat", "te-in": "లైవ్ చాట్" },
      //   path: "live-chat",
      // },
      {
        name: { "en-in": "Contact Us", "te-in": "సంప్రదించండి" },
        path: "contact-us",
      },
    ],
  };

  // const legalities = [
  //   { name: "Privacy Policy", path: "privacy-policy" },
  //   { name: "Refund Policy", path: "refund-policy" },
  //   { name: "Trademarks", path: "trademarks" },
  //   { name: "Terms and Conditions", path: "terms-and-conditions" },
  //   { name: "Cautionary Notice", path: "cautionary-notice" },
  // ];

  const addr = {
    "en-in":
      "A. P Endowments Department, Gollapudi, Vijayawada - 521 225. Andhra Pradesh. India.",
    "te-in":
      "దేవాదాయ ధర్మాదాయ శాఖ, గొల్లపూడి, విజయవాడ - 521225, ఆంధ్ర ప్రదేశ్, భారత్.",
  };

  const vistilToday = {
    "en-in": "Visitors Till Today",
    "te-in": "ఇప్పటి వరకు వెబ్సైట్ సందర్శించినవారు",
  };

  const visToday = {
    "en-in": "Visitors Today",
    "te-in": "ఈరోజు వెబ్సైట్ సందర్శించినవారు",
  };

  const footerHeader = {
    newsletter: {
      "en-in": "Subscribe Newsletter",
      "te-in": "న్యూస్ లెటర్",
    },
    email: { "en-in": "Email Address", "te-in": "ఈ - మెయిల్" },
    app: {
      "en-in": "Download Mobile App",
      "te-in": "మొబైల్ ఆప్ డౌన్లోడ్ చేసుకోండి",
    },
  };

  const dataMap = {
    about,
    values,
    temples,
    "online-data": onlineBooking,
    support,
    media: mediaRoom,
    address: addr,
    tillToday: vistilToday,
    today: visToday,
    footerHeader,
  };
  return dataMap[key];
};
export default FooterData;
