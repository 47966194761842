const commonData = {
  bookNow: { "en-in": "Book Now", "te-in": "బుక్ చేసుకోండి" },
  slogan: {
    "en-in": "Dharmo Rakshathi Rakshithah",
    "te-in": "ధర్మో రక్షతి రక్షితః",
  },
  selectTemple: { "en-in": "Select Temple", "te-in": "దేవస్థానం ఎంచుకోండి" },
  about: { "en-in": "About", "te-in": "అబౌట్" },
  viewMore: { "en-in": "View More", "te-in": "కొనసాగించండి" },
};

export default commonData;
