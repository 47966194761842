import React, { useContext, useState } from "react";
import { withRouter } from "react-router";
import {
  cellphone,
  emblem,
  mail_icon,
  newspaper,
  nineAndNine,
  SD_appstore_icon,
  SD_Call_Icon,
  SD_email_icon,
  SD_Logo_circle,
  sd_map_icon,
  SD_playstore_icon,
  SD_Web_Icon,
  send_icon,
} from "../../Assets/imageList";
import NewsletterSubService from "../../services/sd-newsletter-sub.service";
import "../../Utils/sd-styles/sd-footer.scss";
import "react-toastify/dist/ReactToastify.css";
import { SD_Context } from "../../context/sd-context";
import FooterData from "../../Utils/data/footer.data";
import HeaderData from "../../Utils/data/header.data";

const Footer = React.memo(({ history }) => {
  //-------------------------------------------------------------states----------------------------------------------------------//

  const [errorFlag, setErrorFlag] = useState(false);
  const [subEmail, setSubEmail] = useState("");
  const [successFlag, setSuccessFlag] = useState(false);
  const { visitorCount } = useContext(SD_Context);
  const [errMsg, setMsg] = useState("");
  const { language } = useContext(SD_Context);

  //-------------------------------------------------------------states-----------------------------------------------------------//

  //-------------------------------------------------------------functions--------------------------------------------------------//

  const subscribeButton = async () => {
    try {
      if (subEmail) {
        const re =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(String(subEmail).toLowerCase())) {
          setErrorFlag(true);
          setMsg("Please enter a valid Email address");
          return;
        }
        let res = await NewsletterSubService.subscribe(subEmail, "APEDP");
        if (res) {
          setErrorFlag(false);
          setMsg("");
        }
        // toast.success("Subscribed to newsletter.");
        setSuccessFlag(true);
        setSubEmail("");
      } else {
        setErrorFlag(true);
      }
    } catch (error) {
      setMsg(error.message);
      setErrorFlag(true);

      // toast.error(error.message);
    }
  };
  //-------------------------------------------------------------functions--------------------------------------------------------//

  //-------------------------------------------------------------useEffects--------------------------------------------------------//

  //-------------------------------------------------------------useEffects--------------------------------------------------------//

  return (
    <footer className="sd-footer-main">
      <div className="sd-footer-top">
        <div className="sd-footer-top-right">
          <p>
            <img src={newspaper} alt="=" />
            {FooterData("footerHeader")?.newsletter[language?.code]}
          </p>
          <div className="sd-sub">
            <div
              className={
                errorFlag
                  ? "subscribe sd-red"
                  : subEmail !== ""
                  ? " sd-orange subscribe"
                  : "subscribe"
              }
            >
              <img src={mail_icon} alt="email icon" />

              <input
                type="text"
                maxLength="30"
                placeholder={FooterData("footerHeader")?.email[language?.code]}
                style={{ textTransform: "none" }}
                onKeyUp={(e) => {
                  if (e.keyCode === 13 || e.key === "Enter") {
                    subscribeButton();
                  }
                }}
                onChange={(e) => {
                  if (errorFlag) {
                    setErrorFlag(false);
                  }
                  setSubEmail(e.target.value);
                }}
                value={subEmail}
              />
              <img
                src={send_icon}
                onClick={subscribeButton}
                alt="newsletter icon"
              />
              {errorFlag && <h4>{errMsg}</h4>}
            </div>
          </div>
        </div>
        <div className="sd-footer-top-left">
          <p>
            {" "}
            <img src={cellphone} alt="=" />
            {FooterData("footerHeader")?.app[language?.code]}
          </p>
          <div>
            <img
              src={SD_playstore_icon}
              alt="google play"
              style={{ marginLeft: "10px", marginRight: "10px" }}
            />
            <img src={SD_appstore_icon} alt="google play" />
          </div>
        </div>

        {successFlag && (
          <div className="callout">
            <div className="callout-header">Success</div>
            <span
              className="closebtn"
              onClick={() => {
                setSuccessFlag(false);
              }}
            >
              ×
            </span>
            <div className="callout-container">
              <p>
                Thanks for subscribing!{" "}
                {/* <p >Learn more</p> or close it if it is in your way. */}
              </p>
              <button
                onClick={() => {
                  setSuccessFlag(false);
                }}
              >
                Ok
              </button>
            </div>
          </div>
        )}
      </div>

      <div className="sd-footer-middle">
        <div className="text-center text-lg-start sd-footer-middle-f">
          <div
            className="sd-container-r"
            style={{
              width: "90%!important",
            }}
          >
            <div
              className="row"
              style={{
                display: "flex",
                justifyContent: "space-between",
                textAlign: "initial",
              }}
            >
              <div className="sd-list-body w-16">
                <h5
                  className="text"
                  onClick={() => {
                    history?.push(`/${language?.code}/about`);
                  }}
                >
                  {FooterData("about")?.title[language?.code]}
                </h5>
                <ul className="list-unstyled mb-0">
                  {/* <li>
                    <p
                      className="text-dark"
                      onClick={() => {
                        history?.push(`/${language?.code}/about`);
                      }}
                    >
                      {" "}
                      {HeaderData["overview"][language?.code]}
                    </p>
                  </li> */}
                  {FooterData("about")?.data?.map((data, i) => {
                    return (
                      <li key={i}>
                        <p
                          className="text-dark"
                          onClick={() => {
                            history?.push(`/${language?.code}/${data?.path}`);
                          }}
                        >
                          {data?.name[language?.code]}
                        </p>
                      </li>
                    );
                  })}
                </ul>
              </div>

              <div className="sd-list-body w-18">
                <h5
                  className="text "
                  onClick={() => {
                    history?.push(`/${language?.code}/values`);
                  }}
                >
                  {FooterData("values")?.title[language?.code]}
                </h5>

                <ul className="list-unstyled">
                  {/* <li>
                    <p
                      className="text-dark"
                      onClick={() => {
                        history?.push(`/${language?.code}/values`);
                      }}
                    >
                      {" "}
                      {HeaderData["overview"][language?.code]}
                    </p>
                  </li> */}
                  {FooterData("values")?.data?.map((data, i) => {
                    return (
                      <li key={i}>
                        <p
                          onClick={() => {
                            history?.push(
                              `/${language?.code}/values/${data?.path}`
                            );
                          }}
                          className="text-dark"
                        >
                          {data?.name[language?.code]}
                        </p>
                      </li>
                    );
                  })}
                </ul>
              </div>

              <div className="sd-list-body w-18">
                <h5 className="text">
                  {FooterData("temples")?.title[language?.code]}
                </h5>

                <ul className="list-unstyled mb-0">
                  <li>
                    <p
                      className="text-dark"
                      onClick={() => {
                        history?.push(`/${language?.code}/temples`);
                      }}
                    >
                      {" "}
                      {HeaderData["overview"][language?.code]}
                    </p>
                  </li>
                  {FooterData("temples")?.data?.map((data, i) => {
                    return (
                      <li key={i}>
                        <p
                          className="text-dark"
                          onClick={() => {
                            history?.push(
                              `/${language?.code}/temples/${data?.path}/aboutTemple`
                            );
                          }}
                        >
                          {data?.name[language?.code]}
                        </p>
                      </li>
                    );
                  })}
                </ul>
              </div>

              <div className="sd-list-body w-18">
                <h5 className="text ">
                  {FooterData("online-data")?.title[language?.code]}
                </h5>

                <ul className="list-unstyled">
                  <li>
                    <p
                      className="text-dark"
                      onClick={() => {
                        history?.push(`/${language?.code}/online-booking`);
                      }}
                    >
                      {" "}
                      {HeaderData["overview"][language?.code]}
                    </p>
                  </li>
                  {FooterData("online-data")?.data?.map((data, i) => {
                    return (
                      <li key={i}>
                        <p
                          className="text-dark"
                          onClick={() => {
                            if (localStorage.getItem("accessToken")) {
                              history?.push(
                                `/${language?.code}/devotee-app/online-booking/${data?.path}`
                              );
                            } else {
                              history?.push(
                                `/${language?.code}/devotee/signin?to=devotee-app/online-booking/${data?.path}`
                              );
                            }
                          }}
                        >
                          {data?.name[language?.code]}
                        </p>
                      </li>
                    );
                  })}
                </ul>
              </div>

              <div className="sd-list-body w-15">
                <h5 className="text ">
                  {FooterData("media")?.title[language?.code]}
                </h5>

                <ul className="list-unstyled">
                  <li>
                    <p
                      className="text-dark"
                      onClick={() => {
                        history?.push(`/${language?.code}/media-room`);
                      }}
                    >
                      {" "}
                      {HeaderData["overview"][language?.code]}
                    </p>
                  </li>
                  {FooterData("media")?.data?.map((data, i) => {
                    return (
                      <li key={i}>
                        <p
                          onClick={() => {
                            history?.push(
                              `/${language?.code}/media-room/${data?.path}`
                            );
                          }}
                          className="text-dark"
                        >
                          {data?.name[language?.code]}
                        </p>
                      </li>
                    );
                  })}
                </ul>
              </div>

              <div className="sd-list-body w-15">
                <h5 className="text ">
                  {FooterData("support")?.title[language?.code]}
                </h5>

                <ul className="list-unstyled">
                  <li>
                    <p
                      className="text-dark"
                      onClick={() => {
                        history?.push(`/${language?.code}/support`);
                      }}
                    >
                      {" "}
                      {HeaderData["overview"][language?.code]}
                    </p>
                  </li>
                  {FooterData("support")?.data?.map((data, i) => {
                    return (
                      <li key={i}>
                        <p
                          onClick={() => {
                            history?.push(
                              `/${language?.code}/support/${data?.path}`
                            );
                          }}
                          className="text-dark"
                        >
                          {data?.name[language?.code]}
                        </p>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>

          <div className="sd-additional-footer">
            <div className="emblems">
              <img src={SD_Logo_circle} alt="Image" />
              <img src={emblem} alt="Image" />
            </div>
            <div className="top">
              <p>
                <img src={sd_map_icon} alt="main" />
                {FooterData("address")[language?.code]}
              </p>
            </div>
            <div className="bottom">
              <div className="left ">
                <p>
                  <img src={SD_Call_Icon} alt="number" /> +91 - 98490 05495
                </p>
                <p>
                  <img src={SD_email_icon} alt="mail" className="sep" />
                  endow-edpsec@gov.in
                </p>
                <p>
                  <img src={SD_Web_Icon} alt="mail" />
                  www.aptemples.ap.gov.in
                </p>
              </div>

              <div className="right">
                <div className="left">
                  <p>
                    {" "}
                    {FooterData("tillToday")[language?.code]}{" "}
                    <span>{visitorCount.total ?? 0}</span>
                  </p>{" "}
                </div>
                <div className="left">
                  <p>
                    {FooterData("today")[language?.code]}{" "}
                    <span>{visitorCount.today ?? 0}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="sd-footer-tag">
        <div className="middle">
          <p className="border-r">
            © {new Date().getFullYear()} A. P Endowments Department. All Rights
            Reserved.
          </p>
          <p
            onClick={() => {
              history?.push(`/${language?.code}/privacy-policy`);
            }}
            className="border-r color"
          >
            Privacy Policy
          </p>

          <p
            onClick={() => {
              history?.push(`/${language?.code}/terms-and-conditions`);
            }}
            className=" color"
          >
            Terms & Conditions
          </p>

          {/* <div className="left">
            <img
              onClick={() => {
                window.open(
                  "https://www.facebook.com/sriveeravenkatasatyanarayanaswamy",
                  "_blank"
                );
              }}
              src={facebook}
              alt="icon"
            />

            <img
              onClick={() => {
                window.open(
                  "https://youtube.com/c/AnnavaramDevasthanamLive",

                  "_blank"
                );
              }}
              src={youtube}
              alt="icon"
            />
            <img
              onClick={() => {
                window.open(
                  "https://twitter.com/AnnavaramDevas1?t=CMCZDtumNhJOWCcXQL26JQ&s=09",
                  "_blank"
                );
              }}
              src={twitter}
              alt="icon"
            />
            <img
              onClick={() => {
                window.open(
                  "https://twitter.com/AnnavaramDevas1?t=CMCZDtumNhJOWCcXQL26JQ&s=09",
                  "_blank"
                );
              }}
              src={insta}
              alt="icon"
            />
          </div> */}
        </div>
        <div
          className="right"
          style={{ paddingRight: "25px" }}
          onClick={() => {
            window.open("https://www.9and9.com/", "_blank");
          }}
        >
          Developed by{" "}
          <span>
            {" "}
            <img src={nineAndNine} alt="9and9" />
          </span>
        </div>
      </div>
    </footer>
  );
});
Footer.displayName = "Footer";
export default withRouter(Footer);
