import React, { createContext, useState } from "react";
import Sd_loading from "../Components/sd-common-components/sd-loading";
import Sd_DashboardService from "../services/sd-dashboard.service";
export const SD_Context = createContext();
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UserService from "../services/sd-user.service";
import { API_URL } from "../Utils/sd-axios/sd-api-url";
import { withRouter } from "react-router-dom";
import CommsService from "../services/sd-comms.service";

toast.configure();

const SD_Context_Provider = ({ children, history }) => {
  const AddressRegex = /^[#.0-9a-zA-Z\s,-/]+$/;
  const SITE_KEY = "6LfKG_QaAAAAAI9rFw0CjwXxTjEUWZkCMzVqyba6";
  const [activeTopHeader, setActiveTopHeader] = useState("SrisailaTV");
  const [activeDownHeader, setActiveDownHeader] = useState("");
  const [currentDashboard, setCurrentDashboard] = useState("Srisailam");
  const [userData, setUserData] = useState({});
  const [registerDetails, setRegisterdetails] = useState({});
  const [paymentData, setPaymentData] = useState(null);
  const [paymentPageFlag, setPaymentPageFlag] = useState(false);
  const [resetFlag, setResetFlag] = useState(false);
  const [parokshaSevaData, setParokshaSevaData] = useState([]);
  const [pratyakshaSevaData, setPratyakshaSevaData] = useState([]);
  const [donationData, setDonationData] = useState([]);
  const [currentBookingDonation, setcurrentBookingDonation] = useState(null);
  const [currentDashbaordLower, setCurrentDashboardLower] = useState("");
  const [fav, setFav] = useState([]);
  const [currentBookingPratyaksha, setcurrentBookingPratyaksha] =
    useState(null);
  const [currentBookingPublication, setcurrentBookingPublication] =
    useState(null);
  const templeID = "APEDP";
  const [currentBookingDarshananam, setcurrentBookingDarshananam] =
    useState(null);
  const [currentBookingAccomadations, setcurrentBookingAccomdations] =
    useState(null);
  const [display, setDisplay] = useState(true);
  const [currentBooking, setCurrentBooking] = React.useState(null);
  const [currentBookingDetails, setCurrentBookingDetails] =
    React.useState(null);
  const [devoteeList, setDevoteeList] = useState(null);
  const [publicationData, setPublicationData] = useState([]);
  const [visitorCount, setVisitorCount] = useState({});
  const [language, setlanguage] = useState({ desc: "ENGLISH", code: "en-in" });
  const [languageList, setLanguageList] = useState([
    { desc: "ENGLISH", code: "en-in" },
    { desc: "తెలుగు", code: "te-in" },
  ]);
  const [active, setActive] = useState("devotee-dashboard");
  const [activePath, setActivePath] = useState("Devotee-dashboard");
  const [darshanamData, setDrashanamData] = useState([]);
  const api_url = API_URL;
  const [loading, setLoading] = useState(false);
  const [templeData, setTempleData] = useState([]);
  const [addonsApiRes, setAddonsApiRes] = useState([]);
  const [addonsList, setAddonsList] = useState([]);
  const [addonIds, setAddonIds] = useState([]);
  const [addonSlotIds, setAddonSlotIds] = useState([]);
  const [addonSlotsData, setAddonSlotsData] = useState([]);
  const [addonTypes, setAddonTypes] = useState([]);
  const [selectedCard, setSelectedCard] = useState();
  const [addonPersonStates, setAddonPersonStates] = useState([
    {
      id: 1,
      name: "",
      idProofNumber: "",
    },
  ]);
  const [noPersons, setNoperons] = useState(1);
  const supportedLanguages = ["en-in", "jp-in", "de-in", "es-in", "te-in"];
  const AnnouncementsData = [
    {
      expired: true,
      date: "19/03/2024",
      name: "Srivari Bramhostsavalu - KADIRI",
      link: "https://aped-tms.fra1.digitaloceanspaces.com/production-public-uploads/fe35b47b-efc8-4b70-afd1-d6d833a56025-Booklet%202024_compressed.pdf",
    },
    {
      expired: false,
      date: "14/03/2024",
      name: "AEE Results",
      link: "https://aped-tms.fra1.digitaloceanspaces.com/production-public-uploads/3a4a3f21-1908-4176-84f1-5557824b27c6-AEE%20Results.pdf",
    },
    {
      expired: true,
      date: "21/01/2024",
      name: "AEE Civil Eligible Candidates",
      link: "https://aped-tms.fra1.digitaloceanspaces.com/production-public-uploads/c7ec43c5-7ae7-44c5-9f32-eb055dbc5060-AEE-Civil%20Eligible%20candidates-18%20Jan.pdf",
    },
    {
      expired: true,
      date: "21/01/2024",
      name: "AEE Electrical Eligible Candidates",
      link: "https://aped-tms.fra1.digitaloceanspaces.com/production-public-uploads/3239ec14-becf-4810-89ea-e52057db7f41-AEE-Electrical%20eligible%20candidates-18%20Jan.pdf",
    },
    {
      expired: true,
      date: "21/01/2024",
      name: "Techinical Assistant - Civil",
      link: "https://aped-tms.fra1.digitaloceanspaces.com/production-public-uploads/bb735eca-564a-4d3e-b42d-366dc747bece-Technical%20Assistant-Civil-18%20Jan%20%281%29.pdf",
    },
  ];
  const logout = async () => {
    try {
      setLoading(true);
      await Sd_DashboardService.logout();
      localStorage.clear();
      setCurrentBooking(null);
      setCurrentBookingDetails(null);
      setcurrentBookingAccomdations(null);
      setDevoteeList(null);
      setcurrentBookingPratyaksha(null);
      setcurrentBookingDonation(null);
    } catch (e) {
      setLoading(false);
      // console.log(object);
    }
    setLoading(false);
  };

  function numFormatter(num) {
    if (num > 999 && num < 100000) {
      return (num / 1000).toFixed(2) + "K"; // convert to K for number from > 1000 < 1 million
    }
    if (num > 100000 && num < 10000000) {
      return (num / 100000).toFixed(2) + "L"; // convert to K for number from > 1000 < 1 million
    } else if (num > 10000000) {
      return (num / 10000000).toFixed(2) + "Cr"; // convert to M for number from > 1 million
    } else if (num < 1000) {
      return num; // if value < 1000, nothing to do
    }
  }

  const getFavorities = async () => {
    if (localStorage.getItem("accessToken")) {
      // setLoading(true);
      try {
        let res = await Sd_DashboardService.get_favorities("APEDP");
        setFav(res?.items ?? []);
        return;
      } catch (e) {
        console.log(e);
      }
    } else {
      return;
    }
  };

  const addFavorite = async (data, type) => {
    if (localStorage.getItem("accessToken")) {
      try {
        let datas = {
          type: type,
          itemId: data.id,
        };
        await Sd_DashboardService.add_Favorite(datas, "APEDP");
        toast.success(`Added ${data.name} Favourites updated!!`);
        await getFavorities();
        // console.log(res);
        // return "ok";
      } catch (e) {
        // console.log(e);
        toast.error(e.message);
      }
    } else {
      return "redirect";
      // toast.error("Please login to add favorities");
    }
  };

  const getVisitors = async () => {
    try {
      let res = await UserService.getVisitors(templeID);
      setVisitorCount(res);
    } catch (e) {
      // toast.error(e?.message);
    }
    // console.log(res);
  };

  const removeFavorite = async (id) => {
    if (localStorage.getItem("accessToken")) {
      try {
        await Sd_DashboardService.remove_Favorite(id, "APEDP");
        toast.success(`Removed item from Favourites!`);

        await getFavorities();
        // console.log(res);
      } catch (e) {
        if (e.message === "Session Expired") {
          logout();
          history?.push(`/`);
        }
      }
      // console.log(e);      }}
    } else {
      toast.error("Please login to add favorities");
    }
  };
  const getTempleData = async () => {
    let RedirectTemples = [];
    let NotRedirectTemples = [];
    try {
      let data = await CommsService.getAllTemples();
      data?.map((item) => {
        if (
          item?.devoteePortalConfiguration?.devoteePortalDomain?.includes(
            "aptemples"
          ) ||
          !item?.devoteePortalConfiguration?.devoteePortalDomain ||
          item?.devoteePortalConfiguration?.devoteePortalDomain === null ||
          item?.devoteePortalConfiguration?.devoteePortalDomain === ""
        ) {
          NotRedirectTemples = [...NotRedirectTemples, item];
        } else {
          RedirectTemples = [...RedirectTemples, item];
        }
      });
      setTempleData([...NotRedirectTemples, ...RedirectTemples]);
    } catch (e) {
      toast.error(e?.message);
    }
  };

  // React.useEffect(() => {
  //   getTempleData();
  // }, []);

  return (
    <SD_Context.Provider
      value={{
        SITE_KEY,
        addonPersonStates,
        setAddonPersonStates,
        addonsApiRes,
        setAddonsApiRes,
        addonsList,
        setAddonsList,
        currentBookingPublication,
        setcurrentBookingPublication,
        addonTypes,
        setAddonTypes,
        addonIds,
        setAddonIds,
        addonSlotIds,
        setAddonSlotIds,
        addonSlotsData,
        setAddonSlotsData,
        getTempleData,
        templeData,
        templeID,
        numFormatter,
        getVisitors,
        paymentData,
        setPaymentData,
        publicationData,
        setPublicationData,
        visitorCount,
        setVisitorCount,
        darshanamData,
        setDrashanamData,
        donationData,
        setDonationData,
        addFavorite,
        removeFavorite,
        getFavorities,
        loading,
        setLoading,
        devoteeList,
        setDevoteeList,
        currentBookingPratyaksha,
        setcurrentBookingPratyaksha,
        currentBookingAccomadations,
        setcurrentBookingAccomdations,
        currentBookingDarshananam,
        setcurrentBookingDarshananam,
        display,
        setDisplay,
        currentBookingDonation,
        setcurrentBookingDonation,
        activePath,
        setActivePath,
        active,
        setActive,
        currentBooking,
        setCurrentBooking,
        currentBookingDetails,
        setCurrentBookingDetails,
        currentDashbaordLower,
        setCurrentDashboardLower,
        AnnouncementsData,
        parokshaSevaData,
        setParokshaSevaData,
        language,
        setlanguage,
        registerDetails,
        setRegisterdetails,
        activeTopHeader,
        setActiveTopHeader,
        activeDownHeader,
        setActiveDownHeader,
        currentDashboard,
        setCurrentDashboard,
        userData,
        setUserData,
        api_url,
        pratyakshaSevaData,
        setPratyakshaSevaData,
        fav,
        setFav,
        logout,
        AddressRegex,
        setPaymentPageFlag,
        paymentPageFlag,
        supportedLanguages,
        selectedCard,
        setSelectedCard,
        noPersons,
        setNoperons,
        languageList,
        setLanguageList,
        resetFlag,
        setResetFlag,
      }}
    >
      {loading ? <Sd_loading /> : children}
    </SD_Context.Provider>
  );
};

export default withRouter(SD_Context_Provider);
